'use strict';

import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';
import Routes           from '../../utils/Routes';
import {doFetch}        from './../../utils/CommonUtils';

const CHANGE_EVENTS = {
	FullResult:     'FullResult',
	QuickResult:    'QuickResult',
	ActorsResult:   'ActorsResult',
	VideosResult:   'VideosResult',
	PhotosResult:   'PhotosResult',
	SeriesResult:   'SeriesResult',
	MagazineResult: 'MagazineResult',
};

const SearchResultActionTypes = Constants.ActionTypes.SearchResult;
const MinSearchPatternLength  = Constants.VXMobile.MinSearchPatternLength;
const KeepAliveTime           = 300000;   // 5 min
const ELEMENTS_PER_CALL       = 10;

const _searchResults = {
	Full:       {
		results:       {},
		searchPattern: '',
		currentSearch: '',
	},
	searchType: null,
	Videos:     {
		results:           {},
		searchPattern:     '',
		videosResultCount: 0,
	},
	Magazine:   {
		results:              {},
		searchPattern:        '',
		magazineResutlsCount: 0,
	},
	Actors:     {
		results:           {},
		searchPattern:     '',
		actorsResultCount: 0,
	},
	Photos:     {
		results:           {},
		searchPattern:     '',
		photosResultCount: 0,
	},
	Series:     {
		results:           {},
		searchPattern:     '',
		seriesResultCount: 0,
	},
	Quick:      {
		result:                      null,
		searchPattern:               '',
		mediaSearchResultsCount:     0,
		moreLink:                    null,
		actorsSearchResultsCount:    0,
		videosSearchResultsCount:    0,
		photosSearchResultsCount:    0,
		mediathekSearchResultsCount: 0,
		articlesSearchResultsCount:  0,
		searchType:                  '',
		redirect:                    false,
	},
};

let _language = '';

/**
 * Remove search results from cache (full search only) that are older than KeepAliveTime
 *
 * @private
 */
function _cleanUpSearchResults() {
	const now = Date.now();
	let sp;
	for (sp in _searchResults.Full.results) {
		if (Object.hasOwn(_searchResults.Full.results, sp) && (_searchResults.Full.results[sp].timestamp + KeepAliveTime) < now) {
			delete _searchResults.Full.results[sp];
		}
	}

	for (sp in _searchResults.Actors.results) {
		if (Object.hasOwn(_searchResults.Actors.results, sp) && (_searchResults.Actors.results[sp].timestamp + KeepAliveTime) < now) {
			delete _searchResults.Actors.results[sp];
		}
	}

	for (sp in _searchResults.Videos.results) {
		if (Object.hasOwn(_searchResults.Videos.results, sp) && (_searchResults.Videos.results[sp].timestamp + KeepAliveTime) < now) {
			delete _searchResults.Videos.results[sp];
		}
	}


	for (sp in _searchResults.Photos.results) {
		if (Object.hasOwn(_searchResults.Photos.results, sp) && (_searchResults.Photos.results[sp].timestamp + KeepAliveTime) < now) {
			delete _searchResults.Photos.results[sp];
		}
	}

	for (sp in _searchResults.Series.results) {
		if (Object.hasOwn(_searchResults.Series.results, sp) && (_searchResults.Series.results[sp].timestamp + KeepAliveTime) < now) {
			delete _searchResults.Series.results[sp];
		}
	}

	for (sp in _searchResults.Magazine.results) {
		if (Object.hasOwn(_searchResults.Magazine.results, sp) && (_searchResults.Magazine.results[sp].timestamp + KeepAliveTime) < now) {
			delete _searchResults.Magazine.results[sp];
		}
	}
}

/**
 * Returns a slice of a given search result (max limit entries) or null.
 *
 * @param {array|null} result
 * @param {int} limit
 * @returns {array|null} Slice of search result or null
 * @private
 */
function _getSlice(result, limit) {
	limit = limit || null;

	let slice = result;
	if (result !== null && limit) {
		slice = slice.slice(0, limit);
	}

	return slice;
}

const SearchResultStore = assign({}, FluxEventEmitter.prototype, {
	init: function(language) {
		_language = language;
	},

	addFullResultChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorData, callback);
	},

	addSeachByTypeResultChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.MagazineResult, callback);
		this.on(CHANGE_EVENTS.ActorsResult, callback);
		this.on(CHANGE_EVENTS.VideosResult, callback);
		this.on(CHANGE_EVENTS.SeriesResult, callback);
		this.on(CHANGE_EVENTS.PhotosResult, callback);

	},

	removeFullResultChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.FullResult, callback);
	},

	addQuickResultChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.QuickResult, callback);
	},

	removeQuickResultChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.QuickResult, callback);
	},

	getSearchPatternFull: function() {
		return _searchResults.Full.searchPattern;
	},

	getSearchPatternQuick: function() {
		return _searchResults.Quick.searchPattern;
	},

	getSearchResultsFull: function(searchPattern, limit) {
		searchPattern = searchPattern || _searchResults.Full.searchPattern;

		let result = null;

		if (typeof _searchResults.Full.results[searchPattern] !== 'undefined') {
			result = _searchResults.Full.results[searchPattern].items;
		}

		return _getSlice(result, limit);
	},

	getSearchResultsCurrentCount: function(searchPattern, searchType, sort, order) {
		sort  = sort || null;
		order = order || null;
		let searchKey = searchPattern;

		switch (searchType) {
			case  SearchResultActionTypes.ACTOR_SEARCH:
				if (typeof _searchResults.Actors.results[searchPattern] !== 'undefined') {
					return _searchResults.Actors.results[searchPattern].currentCount;
				}
				break;
			case  SearchResultActionTypes.VIDEOS_SEARCH:
				searchKey = searchPattern;
				if (sort !== null && order !== null) {
					searchKey += sort + order;
				}
				if (typeof _searchResults.Videos.results[searchKey] !== 'undefined') {
					return _searchResults.Videos.results[searchKey].currentCount;
				}
				break;
			case  SearchResultActionTypes.PHOTOS_SEARCH:
				searchKey = searchPattern;
				if (sort !== null && order !== null) {
					searchKey += sort + order;
				}
				if (typeof _searchResults.Photos.results[searchKey] !== 'undefined') {
					return _searchResults.Photos.results[searchKey].currentCount;
				}
				break;
			case  SearchResultActionTypes.SERIES_SEARCH:
				if (typeof _searchResults.Series.results[searchPattern] !== 'undefined') {
					return _searchResults.Series.results[searchPattern].currentCount;
				}
				break;
			case  SearchResultActionTypes.MAGAZINE_SEARCH:
				if (typeof _searchResults.Magazine.results[searchPattern] !== 'undefined') {
					return _searchResults.Magazine.results[searchPattern].currentCount;
				}
				break;
			default:
				break;

		}
	},

	getSearchResultsSortingOptions: function(searchPattern, searchType, sort, order) {
		sort          = sort || null;
		order         = order || null;
		let searchKey = searchPattern;
		if (sort !== null && order !== null) {
			searchKey += sort + order;
		}

		switch (searchType) {
			case  SearchResultActionTypes.VIDEOS_SEARCH:
				if (typeof _searchResults.Videos.results[searchKey] !== 'undefined') {
					return _searchResults.Videos.results[searchKey].sortOptions;
				}
				break;
			case  SearchResultActionTypes.PHOTOS_SEARCH:
				if (typeof _searchResults.Photos.results[searchKey] !== 'undefined') {
					return _searchResults.Photos.results[searchKey].sortOptions;
				}
				break;
			default:
				break;
		}
	},

	getSearchResultsTotalCount: function(searchPattern, searchType, sort, order) {
		sort  = sort || null;
		order = order || null;
		let searchKey = searchPattern;
		switch (searchType) {
			case  SearchResultActionTypes.ACTOR_SEARCH:
				if (typeof _searchResults.Actors.results[searchPattern] !== 'undefined') {
					return _searchResults.Actors.results[searchPattern].totalCount;
				}
				break;
			case  SearchResultActionTypes.VIDEOS_SEARCH:
				searchKey = searchPattern;
				if (sort !== null && order !== null) {
					searchKey += sort + order;
				}
				if (typeof _searchResults.Videos.results[searchKey] !== 'undefined') {
					return _searchResults.Videos.results[searchKey].totalCount;
				}
				break;
			case  SearchResultActionTypes.PHOTOS_SEARCH:
				searchKey = searchPattern;
				if (sort !== null && order !== null) {
					searchKey += sort + order;
				}
				if (typeof _searchResults.Photos.results[searchKey] !== 'undefined') {
					return _searchResults.Photos.results[searchKey].totalCount;
				}
				break;
			case  SearchResultActionTypes.SERIES_SEARCH:
				if (typeof _searchResults.Series.results[searchPattern] !== 'undefined') {
					return _searchResults.Series.results[searchPattern].totalCount;
				}
				break;
			case  SearchResultActionTypes.MAGAZINE_SEARCH:
				if (typeof _searchResults.Magazine.results[searchPattern] !== 'undefined') {
					return _searchResults.Magazine.results[searchPattern].totalCount;
				}
				break;
			default:
				break;

		}
	},

	getSearchResultsByType: function(searchPattern, searchType, sort, order) {
		sort          = sort || null;
		order         = order || null;
		searchPattern = searchPattern || _searchResults.Full.searchPattern;
		let searchKey = searchPattern;

		let result = null;

		switch (searchType) {
			case  SearchResultActionTypes.ACTOR_SEARCH:
				if (typeof _searchResults.Actors.results[searchPattern] !== 'undefined') {
					result = _searchResults.Actors.results[searchPattern].items;
				}
				break;
			case  SearchResultActionTypes.MAGAZINE_SEARCH:
				if (typeof _searchResults.Magazine.results[searchPattern] !== 'undefined') {
					result = _searchResults.Magazine.results[searchPattern].items;
				}
				break;
			case SearchResultActionTypes.VIDEOS_SEARCH:
				searchKey = searchPattern;
				if (order !== null && sort !== null) {
					searchKey += sort + order;
				}

				if (typeof _searchResults.Videos.results[searchKey] !== 'undefined') {
					result = _searchResults.Videos.results[searchKey].items;
				}

				break;
			case SearchResultActionTypes.PHOTOS_SEARCH:
				searchKey = searchPattern;
				if (order !== null && sort !== null) {
					searchKey += sort + order;
				}
				if (typeof _searchResults.Photos.results[searchKey] !== 'undefined') {
					result = _searchResults.Photos.results[searchKey].items;
				}
				break;
			case SearchResultActionTypes.SERIES_SEARCH:
				if (typeof _searchResults.Series.results[searchPattern] !== 'undefined') {
					result = _searchResults.Series.results[searchPattern].items;
				}
				break;
			default:
				break;
		}
		return result;
	},

	getSearchResultsQuick: function(limit) {
		return _getSlice(_searchResults.Quick.result, limit);
	},

	getMediaSearchResultsCount: function() {
		return _searchResults.Quick.mediaSearchResultsCount;
	},

	getActorsSearchResultsCount: function() {
		return _searchResults.Quick.actorsSearchResultsCount;
	},

	getVideosSearchResultsCount: function() {
		return _searchResults.Quick.videosSearchResultsCount;
	},

	getPhotosSearchResultsCount: function() {
		return _searchResults.Quick.photosSearchResultsCount;
	},

	getMediathekSearchResultsCount: function() {
		return _searchResults.Quick.mediathekSearchResultsCount;
	},

	getRedirectToSearchPage: function() {
		return _searchResults.Quick.redirect;
	},

	getArticlesSearchResultsCount: function() {
		return _searchResults.Quick.articlesSearchResultsCount;
	},

	isSearchByTypeRedirectCase: function() {
		return _searchResults.Quick.redirect;
	},

	getSearchType: function() {
		return _searchResults.Quick.searchType;
	},

	getSearchResultsMoreLink: function() {
		return _searchResults.Quick.moreLink;
	},

	getSearchResultsType: function() {
		return _searchResults.searchType;
	},

	setSearchResultsType: function(searchType) {
		_searchResults.searchType = searchType;
	},
});

/**
 * Removes a stored search result from the cache
 *
 * @param {string} searchPattern
 */
function clearResultFull(searchPattern) {
	if (typeof _searchResults.Full.results[searchPattern] !== 'undefined') {
		delete _searchResults.Full.results[searchPattern];
	}
}

/**
 * Get search result for SearchResult page
 *
 * @param {string} searchPattern
 * @param {string} searchType
 * @param {int}    from
 * @param {int}    to
 */
function loadSearchResultFull(searchPattern, searchType, from, to) {
	if (searchPattern.length >= MinSearchPatternLength) {

		doFetch(Routes.getRoute(Routes.Names.SEARCH_RESULT_SEARCH, {
			searchPattern: searchPattern,
			language:      _language,
			searchType:    searchType,
			from:          from,
			to:            to,
		}), {}, Constants.HttpMethods.GET, true).then((result) => {
			setSearchResultFull(result);
		});
	}
}

/**
 * Get search result for SearchResult page
 *
 * @param {string} searchPattern
 * @param {string} searchType
 * @param {int}    from
 * @param {int}    take
 * @param {string} sort
 * @param {string} order

 */
function loadSearchResultByType(searchPattern, searchType, from, take, sort, order) {
	sort  = sort || null;
	order = order || null;

	const isTotalCountNeeded = (from === 0) ? 1 : 0;
	if (searchPattern.length >= MinSearchPatternLength) {

		const route = Routes.getRoute(Routes.Names.SEARCH_RESULT_SEARCH, {
			searchPattern: searchPattern,
			language:      _language,
			searchType:    searchType,
			sort:          sort,
			order:         order,
		});

		doFetch(route, {
			searchPattern:  searchPattern,
			searchType:     searchType,
			from:           from,
			take:           take,
			sort:           sort,
			order:          order,
			withTotalCount: isTotalCountNeeded,
		}, Constants.HttpMethods.GET, true).then((result) => {
			setSearchResultByType(result, searchType);
		});
	}
}

/**
 * Get search results for quick search in NavbarSearchContainer
 *
 * @param {string} searchPattern
 */
function loadSearchResultQuick(searchPattern) {
	if (searchPattern.length >= MinSearchPatternLength) {

		doFetch(Routes.getRoute(Routes.Names.NAVBAR_SEARCH), {
			searchPattern: searchPattern,
			language:      _language,
		}, Constants.HttpMethods.POST, true).then((result) => {
			setSearchResultQuick(result);
		});
	}
}

/**
 * Update the online state for a given actor in all cached search results
 *
 * @param {int} actorId
 * @param {bool} state
 */
function setActorOnlineState(actorId, state) {
	let hasSearchResultChanged = false;

	// clean up unused search results
	_cleanUpSearchResults();

	// cycle through all full results and update actor if in set
	let searchResult, sp, a;
	for (sp in _searchResults.Full.results) {
		if (Object.hasOwn(_searchResults.Full.results, sp)) {
			searchResult = _searchResults.Full.results[sp].items;

			for (a in searchResult) {
				if (Object.hasOwn(searchResult, a) && searchResult[a].actorId === actorId) {
					searchResult[a].isOnline = state;
					hasSearchResultChanged   = true;
					break;
				}
			}
		}
	}

	if (hasSearchResultChanged) {
		SearchResultStore.emit(CHANGE_EVENTS.FullResult);
	}
}

/**
 * Update search pattern for SearchResult page and trigger the search / load the result from the cache
 *
 * @param {string} searchPattern
 * @param {string} searchType
 */
function setPatternFull(searchPattern, searchType) {
	searchPattern = searchPattern.toLowerCase();

	_searchResults.Full.searchPattern = searchPattern;
	SearchResultStore.emit(CHANGE_EVENTS.FullResult);

	if (typeof _searchResults.Full.results[searchPattern] === 'undefined') {
		loadSearchResultFull(searchPattern, searchType);
	} else {
		// update timestamp to keep it in store
		_searchResults.Full.results[searchPattern].timestamp = Date.now();
	}
}

/**
 * Update search pattern for SearchResult page and trigger the search / load the result from the cache
 *
 * @param {string} searchPattern
 * @param {string] searchType
 * @param {int]    sort
 * @param {int]    order
 */
function setPatternByType(searchPattern, searchType, sort, order) {
	sort          = sort || null;
	order         = order || null;
	searchPattern = searchPattern.toLowerCase();
	let searchKey = searchPattern;
	switch (searchType) {
		case SearchResultActionTypes.ACTOR_SEARCH:
			_searchResults.Actors.searchPattern = searchPattern;
			SearchResultStore.emit(CHANGE_EVENTS.ActorsResult);
			if (typeof _searchResults.Actors.results[searchPattern] === 'undefined') {
				// we need to get the results ourselves
				const from = 0;
				const take = ELEMENTS_PER_CALL;
				// we need result total count

				loadSearchResultByType(searchPattern, searchType, from, take);
			} else {
				if (_searchResults.Actors.results[searchPattern].totalCount > _searchResults.Actors.results[searchPattern].currentCount) {
					const from = _searchResults.Actors.results[searchPattern].currentCount;
					const take = ELEMENTS_PER_CALL;
					// we need result total count
					loadSearchResultByType(searchPattern, searchType, from, take);
				}
				else {
					_searchResults.Actors.results[searchPattern].timestamp = Date.now();

				}

			}
			break;
		case SearchResultActionTypes.VIDEOS_SEARCH:
			_searchResults.Videos.searchPattern = searchPattern;
			SearchResultStore.emit(CHANGE_EVENTS.VideosResult);
			searchKey = searchPattern;
			if (sort !== null && order !== null) {
				searchKey += sort + order;
			}
			if (typeof _searchResults.Videos.results[searchKey] === 'undefined') {
				// we need to get the results ourselves
				const from = 0;
				const take = ELEMENTS_PER_CALL;
				// we need result total count
				loadSearchResultByType(searchPattern, searchType, from, take, sort, order);
			} else {
				if (_searchResults.Videos.results[searchKey].totalCount > _searchResults.Videos.results[searchKey].currentCount) {
					const from = _searchResults.Videos.results[searchKey].currentCount;
					const take = ELEMENTS_PER_CALL;
					loadSearchResultByType(searchPattern, searchType, from, take, sort, order);
				}
				else {
					_searchResults.Videos.results[searchKey].timestamp = Date.now();

				}
			}
			break;

		case SearchResultActionTypes.PHOTOS_SEARCH:
			_searchResults.Photos.searchPattern = searchPattern;
			SearchResultStore.emit(CHANGE_EVENTS.PhotosResult);
			searchKey = searchPattern;
			if (sort !== null && order !== null) {
				searchKey += sort + order;
			}
			if (typeof _searchResults.Photos.results[searchKey] === 'undefined') {
				// we need to get the results ourselves
				const from = 0;
				const take = ELEMENTS_PER_CALL;
				// we need result total count
				loadSearchResultByType(searchPattern, searchType, from, take, sort, order);
			} else {
				if (_searchResults.Photos.results[searchKey].totalCount > _searchResults.Photos.results[searchKey].currentCount) {
					const from = _searchResults.Photos.results[searchKey].currentCount;
					const take = ELEMENTS_PER_CALL;
					loadSearchResultByType(searchPattern, searchType, from, take, sort, order);
				}
				else {
					_searchResults.Photos.results[searchKey].timestamp = Date.now();

				}
			}
			break;

		case SearchResultActionTypes.SERIES_SEARCH:
			_searchResults.Series.searchPattern = searchPattern;
			SearchResultStore.emit(CHANGE_EVENTS.SeriesResult);
			if (typeof _searchResults.Series.results[searchPattern] === 'undefined') {
				// we need to get the results ourselves
				const from = 0;
				const take = ELEMENTS_PER_CALL;
				// we need result total count

				loadSearchResultByType(searchPattern, searchType, from, take);
			} else {
				if (_searchResults.Series.results[searchPattern].totalCount > _searchResults.Series.results[searchPattern].currentCount) {
					const from = _searchResults.Series.results[searchPattern].currentCount;
					const take = ELEMENTS_PER_CALL;
					// we need result total count
					loadSearchResultByType(searchPattern, searchType, from, take);
				}
				else {
					// update timestamp to keep it in store
					_searchResults.Series.results[searchPattern].timestamp = Date.now();
				}

			}
			break;
		case SearchResultActionTypes.MAGAZINE_SEARCH:
			_searchResults.Magazine.searchPattern = searchPattern;
			SearchResultStore.emit(CHANGE_EVENTS.MagazineResult);
			if (typeof _searchResults.Magazine.results[searchPattern] === 'undefined') {
				const from = 0;
				const take = ELEMENTS_PER_CALL;
				// we need result total count
				loadSearchResultByType(searchPattern, searchType, from, take);
			} else {
				if (_searchResults.Magazine.results[searchPattern].totalCount > _searchResults.Magazine.results[searchPattern].currentCount) {
					const from = _searchResults.Magazine.results[searchPattern].currentCount;
					const take = ELEMENTS_PER_CALL;
					// we need result total count
					loadSearchResultByType(searchPattern, searchType, from, take);
				}
				else {
					_searchResults.Magazine.results[searchPattern].timestamp = Date.now();
				}
			}
			break;
		default:
			break;
	}

}

/**
 * Update search pattern for quick search in NavbarSearchContainer and trigger search
 *
 * @param {string} searchPattern
 */
function setPatternQuick(searchPattern) {
	searchPattern = searchPattern.toLowerCase();

	_searchResults.Quick.result        = null;
	_searchResults.Quick.searchPattern = searchPattern;

	SearchResultStore.emit(CHANGE_EVENTS.QuickResult);
	loadSearchResultQuick(searchPattern);
}

/**
 * Store search result for SearchResult page in cache
 *
 * @param {object} data
 */
function setSearchResultFull(data) {
	_searchResults.Full.results[data.searchPattern] = {
		items:                data.items,
		timestamp:            Date.now(),
		searchPattern:        '',
		actorsResultCount:    data.actorsResultCount,
		actorsConfig:         data.actorsConfig,
		videosResultCount:    data.videosResultCount,
		videoConfig:          data.videoConfig,
		photosResultCount:    data.photosResultCount,
		photosConfig:         data.photosConfig,
		seriesResultCount:    data.seriesResultCount,
		seriesResultConfig:   data.seriesResultConfig,
		magazineResultsCount: data.magazineResultsCount,
		magazineConfig:       data.magazineConfig,
		searchType:           data.searchType,
	};
	SearchResultStore.emit(CHANGE_EVENTS.ActorsResult);
}


function concatItems(tileGridConfig1, tileGridConfig2) {
	return tileGridConfig1.concat(tileGridConfig2);
}


/**
 * Store search result for SearchResult page in cache
 *
 * @param {object} data
 * @param {string} type
 */

function setSearchResultByType(data, type) {
	let searchKey = data.searchPattern;
	let totalCount = 0;

	switch (type) {
		case SearchResultActionTypes.ACTOR_SEARCH:
			if (typeof _searchResults.Actors.results[data.searchPattern] === 'undefined') {
				data.items.initialTiles = concatItems([], data.items.initialTiles);
			}
			else {
				data.items.initialTiles = concatItems(_searchResults.Actors.results[data.searchPattern].items.initialTiles, data.items.initialTiles);
			}
			totalCount = (typeof data.totalCount !== 'undefined') ? data.totalCount : _searchResults.Actors.results[data.searchPattern].totalCount;
			_searchResults.Actors.results[data.searchPattern] = {
				items:         data.items,
				timestamp:     Date.now(),
				searchPattern: data.searchPattern,
				searchType:    SearchResultActionTypes.ACTOR_SEARCH,
				totalCount:    totalCount,
				currentCount:  Object.keys(data.items.initialTiles).length,
			};
			SearchResultStore.emit(CHANGE_EVENTS.ActorsResult);
			break;

		case SearchResultActionTypes.VIDEOS_SEARCH:
			searchKey = data.searchPattern;
			if (typeof data.order !== 'undefined' && typeof data.sort !== 'undefined') {
				searchKey += data.sort + data.order;
			}

			totalCount = (typeof data.totalCount !== 'undefined') ? data.totalCount : _searchResults.Videos.results[searchKey].totalCount;

			if (typeof _searchResults.Videos.results[searchKey] === 'undefined') {
				data.items.initialTiles = concatItems([], data.items.initialTiles);
			}
			else {
				data.items.initialTiles = concatItems(_searchResults.Videos.results[searchKey].items.initialTiles, data.items.initialTiles);
			}

			_searchResults.Videos.results[searchKey] = {
				items:         data.items,
				timestamp:     Date.now(),
				sortOptions:   data.sortOptions,
				searchPattern: '',
				searchType:    SearchResultActionTypes.VIDEOS_SEARCH,
				totalCount:    totalCount,
				currentCount:  Object.keys(data.items.initialTiles).length,
			};
			SearchResultStore.emit(CHANGE_EVENTS.VideosResult);
			break;

		case SearchResultActionTypes.PHOTOS_SEARCH:
			searchKey = data.searchPattern;
			if (typeof data.order !== 'undefined' && typeof data.sort !== 'undefined') {
				searchKey += data.sort + data.order;
			}

			totalCount = (typeof data.totalCount !== 'undefined') ? data.totalCount : _searchResults.Photos.results[searchKey].totalCount;

			if (typeof _searchResults.Photos.results[searchKey] === 'undefined') {
				data.items.initialTiles = concatItems([], data.items.initialTiles);
			}
			else {
				data.items.initialTiles = concatItems(_searchResults.Photos.results[searchKey].items.initialTiles, data.items.initialTiles);
			}

			_searchResults.Photos.results[searchKey] = {
				items:         data.items,
				timestamp:     Date.now(),
				sortOptions:   data.sortOptions,
				searchPattern: '',
				searchType:    SearchResultActionTypes.PHOTOS_SEARCH,
				totalCount:    totalCount,
				currentCount:  Object.keys(data.items.initialTiles).length,
			};
			SearchResultStore.emit(CHANGE_EVENTS.PhotosResult);
			break;

		case SearchResultActionTypes.SERIES_SEARCH:
			if (typeof _searchResults.Series.results[data.searchPattern] === 'undefined') {
				data.items.initialTiles = concatItems([], data.items.initialTiles);
			}
			else {
				data.items.initialTiles = concatItems(_searchResults.Series.results[data.searchPattern].items.initialTiles, data.items.initialTiles);
			}

			totalCount                                        = (typeof data.totalCount !== 'undefined') ? data.totalCount : _searchResults.Series.results[data.searchPattern].totalCount;
			_searchResults.Series.results[data.searchPattern] = {
				items:         data.items,
				timestamp:     Date.now(),
				searchPattern: '',
				searchType:    data.searchType,
				totalCount:    totalCount,
				currentCount:  Object.keys(data.items.initialTiles).length,
			};
			SearchResultStore.emit(CHANGE_EVENTS.SeriesResult);


			break;

		case SearchResultActionTypes.MAGAZINE_SEARCH:
			//("Receivng the results");
			if (typeof _searchResults.Magazine.results[data.searchPattern] === 'undefined') {
				data.items.initialTiles = concatItems([], data.items.initialTiles);
			}
			else {
				data.items.initialTiles = concatItems(_searchResults.Magazine.results[data.searchPattern].items.initialTiles, data.items.initialTiles);
			}

			totalCount                                          = (typeof data.totalCount !== 'undefined') ? data.totalCount : _searchResults.Magazine.results[data.searchPattern].totalCount;
			_searchResults.Magazine.results[data.searchPattern] = {
				items:         data.items,
				timestamp:     Date.now(),
				searchPattern: '',
				searchType:    data.searchType,
				totalCount:    totalCount,
				currentCount:  Object.keys(data.items.initialTiles).length,
			};

			SearchResultStore.emit(CHANGE_EVENTS.MagazineResult, type);
			break;
		default:
			break;
	}

}


/**
 * Store search result for NavbarSearchContainer in cache
 * @param data
 */
function setSearchResultQuick(data) {
	if (typeof data.searchPattern !== 'undefined' && data.searchPattern === _searchResults.Quick.searchPattern) {
		_searchResults.Quick.result                      = data.items;
		_searchResults.Quick.moreLink                    = data.moreLink;
		_searchResults.Quick.mediaSearchResultsCount     = data.mediaSearchResults;
		_searchResults.Quick.actorsSearchResultsCount    = data.actorsSearchCount;
		_searchResults.Quick.videosSearchResultsCount    = data.videosSearchCount;
		_searchResults.Quick.photosSearchResultsCount    = data.photosSearchCount;
		_searchResults.Quick.mediathekSearchResultsCount = data.tvMediathekSearchCount;
		_searchResults.Quick.articlesSearchResultsCount  = data.magazineSearchCount;
		_searchResults.Quick.searchType                  = data.searchType;
		_searchResults.Quick.searchPattern               = data.searchPattern;
		_searchResults.Quick.redirect                    = data.redirect;

		SearchResultStore.emit(CHANGE_EVENTS.QuickResult);
	}

}

/**
 * Dispatch incoming requests
 *
 * @type {DispatchToken}
 */
SearchResultStore.dispatchToken = Dispatcher.register((action) => {

	switch (action.type) {
		case SearchResultActionTypes.CLEAR_RESULT_FULL:
			clearResultFull(action.searchPattern);
			break;
		case SearchResultActionTypes.SET_ACTOR_ONLINE_STATE:
			setActorOnlineState(action.actorId, action.state);
			break;
		case SearchResultActionTypes.SET_PATTERN_FULL:
			setPatternFull(action.searchPattern, action.searchType);
			break;
		case SearchResultActionTypes.ACTOR_SEARCH:
		case SearchResultActionTypes.MAGAZINE_SEARCH:
		case SearchResultActionTypes.VIDEOS_SEARCH:
		case SearchResultActionTypes.SERIES_SEARCH:
		case SearchResultActionTypes.PHOTOS_SEARCH:
			setPatternByType(action.searchPattern, action.searchType, action.sort, action.order);
			break;
		case SearchResultActionTypes.SET_PATTERN_QUICK:
			setPatternQuick(action.searchPattern);
			break;
		default:
	}
});

export default SearchResultStore;