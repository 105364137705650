const VideoFilterActionTypes = {
	SET_TYPE:                   'VIDEO_FILTER_SET_TYPE',
	SET_SORT:                   'VIDEO_FILTER_SET_SORT',
	SET_DURATION:               'VIDEO_FILTER_SET_DURATION',
	SET_DURATION_FROM:          'VIDEO_FILTER_SET_DURATION_FROM',
	SET_DURATION_TO:            'VIDEO_FILTER_SET_DURATION_TO',
	TOGGLE_GENDER:              'VIDEO_FILTER_TOGGLE_GENDER',
	CLEAR_TAGS:                 'VIDEO_FILTER_CLEAR_TAGS',
	TOGGLE_TAG:                 'VIDEO_FILTER_TOGGLE_TAG',
	SET_IS_RECOMMENDED:         'VIDEO_FILTER_SET_IS_RECOMMENDED',
	SET_INCLUDE_BOUGHT_VIDEOS:  'VIDEO_FILTER_SET_INCLUDE_BOUGHT_VIDEOS',
	SET_FREE_VIDEOS_ONLY:       'VIDEO_FILTER_SET_FREE_VIDEOS_ONLY',
	SET_INCLUDE_FREE_VIDEOS:    'VIDEO_FILTER_SET_INCLUDE_FREE_VIDEOS',
	SET_INCLUDE_ON_SALE_VIDEOS: 'VIDEO_FILTER_SET_INCLUDE_ON_SALE_VIDEOS',
	SET_INCLUDE_VIP_VIDEOS:     'VIDEO_FILTER_SET_INCLUDE_VIP_VIDEOS',
	SET_PAGE:                   'VIDEO_FILTER_SET_PAGE',
	SET_PAGE_SIZE:              'VIDEO_FILTER_SET_PAGE_SIZE',
};

export default VideoFilterActionTypes;
