/* global VXConfig, ga */
'use strict';

import Dispatcher                      from '../Dispatcher';
import Constants                       from '../Constants';
import {doFetch, getCookie, setCookie} from '../../utils/CommonUtils';
import FluxEventEmitter                from '../FluxEventEmitter';
import assign                          from 'object-assign';
import Routes                          from '../../utils/Routes';
import Flux                            from '../Flux';

const CHANGE_EVENTS = {
	BeforeInstallPrompt: 'beforeInstallPromptChange',
	DisableBodyScroll:   'disableBodyScrollChange',
	//GuestSubMenu:        'guestSubMenu',
	HideNavbar:    'hideNavbar',
	ContentScroll: 'contentScroll',
	Menu:          'menu',
	ScrollTo:      'scrollToChange',
};

const VXMobileActionTypes = Constants.ActionTypes.VXMobile;

let _beforeInstallPrompt           = null;
let _beforeInstallPromptListener   = null;
let _beforeInstallPromptShown      = true;
let _refererUrl                    = '';
let _language                      = '';
let _platform                      = '';
let _desktopLink                   = '';
let _isDevServer                   = false;
let _isEntryPage                   = false;
let _restoreNavbarState            = false;
let _vxBaseUrl                     = '';
let _routeName                     = '';
let _routeArgs                     = {};
const _containerScrollListeners    = {};
const _elementsDisablingBodyScroll = [];
const _elementsHidingNavbar        = [];
const _menus                       = {};
const _navbarStates                = {};
const _scrollToOnMount             = {};

const _guestData = {
	avatar:            '',
	guestName:         '',
	hasAvatar:         false,
	id:                null,
	isLoggedIn:        false,
	maxChannelsToShow: 5,
	subMenu:           {
		heights:  {},
		openMenu: null,
	},
	tileGridSettings:  {},
};

function _getMenuOpen(menuId) {
	menuId = menuId || null;

	let m, subMenus;
	let menuOpen = null;

	if (menuId === null) {
		for (m in _menus) {
			if (Object.hasOwn(_menus, m) && _menus[m].isOpen) {
				menuOpen = m;
			}
		}
	} else {
		subMenus = _registerMenu(menuId).subMenus;

		for (m in subMenus) {
			if (Object.hasOwn(subMenus, m) && subMenus[m].isOpen) {
				menuOpen = m;
			}
		}
	}

	return menuOpen;
}

function _initInstallPrompt() {
	// remove old listener (window based)
	if (window.a2hs) {
		window.removeEventListener('beforeinstallprompt', window.a2hs);
		window.a2hs = null;
	}

	// install new listener (store based)
	if (!_beforeInstallPromptListener) {
		_beforeInstallPromptListener = true;
		window.addEventListener('beforeinstallprompt', _setInstallPromptEvent);
	}

	_setInstallPromptEvent(VXConfig.beforeInstallPrompt);

	// unset beforeInstallPrompt on VXConfig to avoid duplicate ga tracking
	VXConfig.beforeInstallPrompt = null;
}

function _registerMenu(menuId, subMenuId) {
	subMenuId = subMenuId || null;

	if (typeof _menus[menuId] === 'undefined') {
		_menus[menuId] = {
			isOpen:   false,
			subMenus: {},
		};
	}

	if (subMenuId !== null && typeof _menus[menuId].subMenus[subMenuId] === 'undefined') {
		_menus[menuId].subMenus[subMenuId] = {
			isBlocking: true,
			isOpen:     false,
			data:       {},
		};
	}

	return subMenuId === null ? _menus[menuId] : _menus[menuId].subMenus[subMenuId];
}

function _resolveInstallPrompt(choiceResult) {
	if (window.ga) {
		if (choiceResult.outcome === 'dismissed') {
			ga('send', 'event', 'User', 'add_to_homescreen_dismiss');
		} else {
			ga('send', 'event', 'User', 'add_to_homescreen_accept');
		}
	}

	// remove the install button since the prompt() may only be called once
	_setInstallPromptEvent(null);
}

function _setInstallPromptEvent(e) {
	_beforeInstallPrompt = e;
	VXMobileStore.emit(CHANGE_EVENTS.BeforeInstallPrompt);

	// bind resolver to userChoice
	if (_beforeInstallPrompt) {
		_beforeInstallPrompt.userChoice.then(_resolveInstallPrompt);
	}

	if (getCookie(Constants.CookieNames.BEFORE_INSTALL_PROMPT)) {
		_beforeInstallPromptShown = false;
	}
}

const VXMobileStore = assign({}, FluxEventEmitter.prototype, {
	init: function(data) {
		_language                  = data.language;
		_platform                  = data.platform;
		_desktopLink               = data.desktopLink;
		_isDevServer               = data.isDevServer;
		_refererUrl                = data.refererUrl;
		_isEntryPage               = data.isEntryPage;
		_vxBaseUrl                 = data.vxBaseUrl;
		setRoute(VXConfig.routeName, VXConfig.routeArgs);


		if (data.guest) {
			_guestData.avatar           = data.guest.avatar;
			_guestData.guestName        = data.guest.guestName;
			_guestData.hasAvatar        = data.guest.hasAvatar;
			_guestData.id               = data.guest.id;
			_guestData.isLoggedIn       = true;
			_guestData.tileGridSettings = data.guest.tileGridSettings;
		}

		_initInstallPrompt();
	},

	addBeforeInstallPromptChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.BeforeInstallPrompt, callback);
	},

	removeBeforeInstallPromptChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.BeforeInstallPrompt, callback);
	},

	addDisableBodyScrollChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.DisableBodyScroll, callback);
	},

	removeDisableBodyScrollChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.DisableBodyScroll, callback);
	},

	addHideNavbarChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.HideNavbar, callback);
	},

	removeHideNavbarChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.HideNavbar, callback);
	},

	addContentScrollChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ContentScroll, callback);
	},

	removeContentScrollChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ContentScroll, callback);
	},

	addMenuChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.Menu, callback);
	},

	removeMenuChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.Menu, callback);
	},

	addScrollPageToListener: function(callback) {
		this.on(CHANGE_EVENTS.ScrollTo, callback);
	},

	removeScrollPageToListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ScrollTo, callback);
	},

	getLanguage: function() {
		return _language;
	},

	getPlatform: function() {
		return _platform;
	},

	getDesktopLink: function() {
		return _desktopLink;
	},

	getRefererUrl: function() {
		return _refererUrl;
	},
	getMenu:                      function(menuId) {
		return _registerMenu(menuId);
	},

	getMenuOpen: function() {
		return _getMenuOpen();
	},

	getRouteName: function() {
		return _routeName;
	},

	getRouteArgs: function() {
		return _routeArgs;
	},

	getScrollToOnMount: function(route) {
		return _scrollToOnMount[route];
	},

	getSubMenu: function(menuId, subMenuId) {
		return _registerMenu(menuId, subMenuId);
	},

	getSubMenuOpen: function(menuId) {
		return _getMenuOpen(menuId);
	},

	getVxBaseUrl: function() {
		return _vxBaseUrl;
	},

	isBeforeInstallPromptSet() {
		return _beforeInstallPrompt && _beforeInstallPromptShown;
	},

	isBodyScrollDisabled: function() {
		return _elementsDisablingBodyScroll.length > 0;
	},

	isDevServer: function() {
		return _isDevServer;
	},

	isEntryPage: function() {
		return _isEntryPage;
	},

	isIOS: function() {
		return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	},

	isNavbarHidden: function() {
		return _elementsHidingNavbar.length > 0;
	},

	shouldRestoreNavbarState: function() {
		return _restoreNavbarState === true;
	},

	addBodyOverflowHidden: function() {
		addBodyOverflowHidden();
	},

	removeBodyOverflowHidden: function() {
		removeBodyOverflowHidden();
	},
	showNavigation: function(withEffect = false) {
		showNavigation(withEffect);
	},
	hideNavigation: function(withEffect = false) {
		hideNavigation(withEffect);
	},

	Guest: {
		getAvatar: function() {
			return _guestData.avatar;
		},

		getTileGridSettingsByType: function(type) {
			let settings = {};
			if (typeof _guestData.tileGridSettings[type] !== 'undefined') {
				settings = _guestData.tileGridSettings[type];
			}

			return settings;
		},

		hasAvatar: function() {
			return _guestData.hasAvatar;
		},
	},
});


function _createContainerScrollListener(listener) {
	return () => {
		if (listener.ignoreNextScroll) {
			listener.ignoreNextScroll = false;
		} else if (!listener.timeout && _elementsDisablingBodyScroll.length === 0) {
			listener.timeout = window.setTimeout(() => {
				const curContainerHeight  = listener.container.clientHeight;
				const curScrollPosition   = listener.container.scrollTop;
				const prevContainerHeight = listener.containerHeight;
				const prevScrollPosition  = listener.scrollPosition;

				// update scrollPosition and containerHeight
				listener.containerHeight = curContainerHeight;
				listener.scrollPosition  = curScrollPosition;

				const heightDiff    = prevContainerHeight - curContainerHeight;
				const scrollDiff    = curScrollPosition - prevScrollPosition;
				const heightDiffAbs = Math.abs(heightDiff);
				const scrollDiffAbs = Math.abs(scrollDiff);
				let navbarToggled   = false;

				// content did not scroll if only the navbar was toggled and the scroll difference is the container's height difference
				if (heightDiff < 0 && scrollDiff < 0 || heightDiff > 0 && scrollDiff > 0) {
					navbarToggled = Math.abs(heightDiffAbs - scrollDiffAbs) <= 5;
				}

				if (scrollDiffAbs > 10 && !navbarToggled) {

					// scrolled up
					if (scrollDiff < 0) {
						showNavbar(listener.id);
					} else if (curScrollPosition > 50) {
						hideNavbar(listener.id);
					}

					if (listener.callback) {
						listener.callback(curScrollPosition, prevScrollPosition, listener.container.clientHeight, listener.content.clientHeight);
					}
				}

				window.clearTimeout(listener.timeout);
				listener.timeout = null;
			}, 200);
		}
	};
}

function addContainerScrollListener(containerId, contentId, callback) {
	const container  = document.getElementById(containerId);
	const content    = document.getElementById(contentId);
	const listenerId = containerId + ';' + contentId;

	if (container && content && !_containerScrollListeners[listenerId]) {
		const listener = {
			callback:         callback,
			containerHeight:  container.clientHeight,
			container:        container,
			containerId:      containerId,
			content:          content,
			id:               listenerId,
			ignoreNextScroll: false,
			scrollPosition:   0,
			timeout:          null,
		};

		listener.func = _createContainerScrollListener(listener);

		container.addEventListener('scroll', listener.func);

		_containerScrollListeners[listenerId] = listener;
	}
}

function clickBeforeInstallPrompt(install) {
	if (install) {
		if (_beforeInstallPrompt) {
			if (window.ga) {
				ga('send', 'event', 'User', 'add_to_homescreen_install');
			}

			_beforeInstallPrompt.prompt().then(() => {
				// ignore
			}).catch(() => {
				_setInstallPromptEvent(null);
			});
		} else {
			_setInstallPromptEvent(null);
		}
	} else {
		if (window.ga) {
			ga('send', 'event', 'User', 'add_to_homescreen_close');
		}

		// set cookie to hide install prompt for some days
		setCookie(Constants.CookieNames.BEFORE_INSTALL_PROMPT, true, 7, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
		_beforeInstallPromptShown = false;

		_setInstallPromptEvent(null);
	}
}

function disableBodyScroll(triggerName) {
	if (_elementsDisablingBodyScroll.indexOf(triggerName) === -1) {
		_elementsDisablingBodyScroll.push(triggerName);
		VXMobileStore.emit(CHANGE_EVENTS.DisableBodyScroll);
	}
}

function enableBodyScroll(triggerName) {
	const pos = _elementsDisablingBodyScroll.indexOf(triggerName);
	if (pos > -1) {
		_elementsDisablingBodyScroll.splice(pos, 1);
		VXMobileStore.emit(CHANGE_EVENTS.DisableBodyScroll);
	}
}

function hideNavbar(triggerName) {
	if (_elementsHidingNavbar.indexOf(triggerName) === -1) {
		_elementsHidingNavbar.push(triggerName);
		VXMobileStore.emit(CHANGE_EVENTS.HideNavbar);
	}
}

function setContentScroll(scrollPosition) {
	if (typeof scrollPosition === 'number') {
		VXMobileStore.emit(CHANGE_EVENTS.ContentScroll, scrollPosition);
	}
}

function ignoreNextScroll(containerId) {
	for (const listenerId in _containerScrollListeners) {
		if (Object.hasOwn(_containerScrollListeners, listenerId) && _containerScrollListeners[listenerId] && _containerScrollListeners[listenerId].containerId === containerId) {
			_containerScrollListeners[listenerId].ignoreNextScroll = true;
		}
	}
}

function removeContainerScrollListener(containerId, contentId) {
	const listenerId = containerId + ';' + contentId;
	const listener   = _containerScrollListeners[listenerId];
	if (typeof listener !== 'undefined') {
		listener.container.removeEventListener('scroll', listener.func);

		if (listener.timeout) {
			clearTimeout(listener.timeout);
		}

		_containerScrollListeners[listenerId] = undefined;
	}
}

function restoreNavbarState(path) {
	if (typeof _navbarStates[path] !== 'undefined') {
		_elementsHidingNavbar.length = 0;

		for (let e = 0; e < _navbarStates[path].length; e++) {
			_elementsHidingNavbar.push(_navbarStates[path][e]);
		}

		VXMobileStore.emit(CHANGE_EVENTS.HideNavbar);
	}
}

function scrollPageTo(position) {
	setTimeout(() => {
		VXMobileStore.emit(CHANGE_EVENTS.ScrollTo, position);
	}, 0);
}

function setRestoreNavbarState(restore) {
	_restoreNavbarState = restore;
}

function setRoute(routeName, routeArgs) {
	_routeName = routeName;
	_routeArgs = routeArgs;
}

function setScrollToOnMount(route, position) {
	_scrollToOnMount[route] = position;
}

function showNavbar(triggerName, callback) {
	let hasStatusChanged = false;
	if (!triggerName) {
		hasStatusChanged             = _elementsHidingNavbar.length > 0;
		_elementsHidingNavbar.length = 0;
	} else {
		const pos = _elementsHidingNavbar.indexOf(triggerName);
		if (pos > -1) {
			_elementsHidingNavbar.splice(pos, 1);
			hasStatusChanged = true;
		}
	}

	if (hasStatusChanged) {
		VXMobileStore.emit(CHANGE_EVENTS.HideNavbar, callback);
	} else if (typeof callback === 'function') {
		setTimeout(callback, 0);
	}
}

function storeNavbarState(path) {
	_navbarStates[path] = _elementsHidingNavbar.slice();
}

function storeTileGridSettingsByType(type, settings) {

	doFetch(Routes.getRoute(Routes.Names.TILE_GRID_STORE_TILE_GRID_SETTINGS), {
		type:     type,
		settings: JSON.stringify(settings),
	}, Constants.HttpMethods.POST, true).then((result) => {
		if (result.settings) {
			_guestData.tileGridSettings = result.settings;
		}
	});
}

function addBodyOverflowHidden() {
	document.querySelector('body').classList.add('hide-overflow');
}

function removeBodyOverflowHidden() {
	document.querySelector('body').classList.remove('hide-overflow');
}

function showNavigation(withEffect = false) {
	const vxnavigation = document.getElementById('vxnavigation');
	if (vxnavigation) {
		if (withEffect) {
			vxnavigation.classList.remove('vxnavigation--hide-effect');
		} else {
			vxnavigation.classList.remove('vxnavigation--hide');
		}
	}
}

function hideNavigation(withEffect = false) {
	const vxnavigation = document.getElementById('vxnavigation');
	if (vxnavigation) {
		if (withEffect) {
			vxnavigation.classList.add('vxnavigation--hide-effect');
		} else {
			vxnavigation.classList.add('vxnavigation--hide');
		}
	}
}

/**
 *
 * @param menuId
 * @param {boolean} toggle - true: toggle, false: close
 */
function toggleMenu(menuId, toggle) {
	let m, sm;

	if (menuId === null) {
		menuId = _getMenuOpen();
	}

	const menu  = _registerMenu(menuId);
	const state = toggle && !menu.isOpen;

	// state = false -> close menu
	if (!state) {
		// first: check for remaining sub menus that need to be closed
		let hasOpenSubMenus = false;
		for (sm in menu.subMenus) {
			if (Object.hasOwn(menu.subMenus, sm) && menu.subMenus[sm].isOpen) {
				menu.subMenus[sm].isOpen = false;

				if (menu.subMenus[sm].isBlocking) {
					hasOpenSubMenus = true;
				}
			}
		}

		if (hasOpenSubMenus && toggle) {
			VXMobileStore.emit(CHANGE_EVENTS.Menu);
		} else if (menu.isOpen) {
			menu.isOpen = false;
			VXMobileStore.emit(CHANGE_EVENTS.Menu);
		}
	} else {
		for (m in _menus) {
			if (Object.hasOwn(_menus, m)) {
				_menus[m].isOpen = false;

				// close all submenus
				for (sm in _menus[m].subMenus) {
					if (Object.hasOwn(_menus[m].subMenus, sm)) {
						_menus[m].subMenus[sm].isOpen = false;
					}
				}
			}
		}

		menu.isOpen = true;
		VXMobileStore.emit(CHANGE_EVENTS.Menu);
	}

	if (VXMobileStore.getMenuOpen() !== null) {
		disableBodyScroll('menu');
	} else {
		enableBodyScroll('menu');
	}
}

/**
 *
 * @param menuId
 * @param subMenuId
 * @param {boolean} toggle - true: toggle, false: close
 * @param {boolean} isBlocking - true: when toggling the main menu blocking subMenus will be closed first; only when no blocking subMenus are open the main menu will be closed
 */
function toggleSubMenu(menuId, subMenuId, toggle, isBlocking) {
	if (typeof isBlocking === 'undefined') {
		isBlocking = true;
	}

	let sm, state, menu, subMenu;

	if (menuId) {
		if (subMenuId === null) {
			subMenuId = _getMenuOpen(menuId);
		}

		subMenu = _registerMenu(menuId, subMenuId);
		state   = toggle && !subMenu.isOpen;

		// state = false -> close menu
		if (!state) {
			if (subMenu.isOpen) {
				subMenu.isOpen = false;
				VXMobileStore.emit(CHANGE_EVENTS.Menu);
			}
		} else {
			menu = _registerMenu(menuId);

			// close all submenus
			for (sm in menu.subMenus) {
				if (Object.hasOwn(menu.subMenus, sm)) {
					menu.subMenus[sm].isOpen = false;
				}
			}

			subMenu.isBlocking = !!isBlocking;
			subMenu.isOpen     = true;
			VXMobileStore.emit(CHANGE_EVENTS.Menu);
		}
	}
}

function updateSubMenu(menuId, subMenuId, data) {
	const subMenu  = _registerMenu(menuId, subMenuId);
	let hasChanged = false;

	for (const d in data) {
		if (Object.hasOwn(data, d)) {
			if (typeof subMenu.data[d] === 'undefined' || subMenu.data[d] !== data[d]) {
				hasChanged = true;
			}
			subMenu.data[d] = data[d];
		}
	}

	if (hasChanged) {
		VXMobileStore.emit(CHANGE_EVENTS.Menu);
	}
}


VXMobileStore.dispatchToken = Dispatcher.register((action) => {
	switch (action.type) {
		case VXMobileActionTypes.ADD_CONTAINER_SCROLL_LISTENER:
			addContainerScrollListener(action.containerId, action.contentId, action.callback);
			break;
		case VXMobileActionTypes.CLICK_BEFORE_INSTALL_PROMPT:
			clickBeforeInstallPrompt(action.install);
			break;
		case VXMobileActionTypes.CLOSE_MENU:
			toggleMenu(action.menuId, false);
			break;
		case VXMobileActionTypes.CLOSE_SUBMENU:
			toggleSubMenu(action.menuId, action.subMenuId, false);
			break;
		case VXMobileActionTypes.DISABLE_BODY_SCROLL:
			disableBodyScroll(action.triggerName);
			break;
		case VXMobileActionTypes.ENABLE_BODY_SCROLL:
			enableBodyScroll(action.triggerName);
			break;
		case VXMobileActionTypes.GUEST_STORE_TILE_GRID_SETTINGS:
			storeTileGridSettingsByType(action.tileGridType, action.settings);
			break;
		case VXMobileActionTypes.HIDE_NAVBAR:
			hideNavbar(action.triggerName);
			break;
		case VXMobileActionTypes.IGNORE_NEXT_SCROLL:
			ignoreNextScroll(action.containerId);
			break;
		case VXMobileActionTypes.REMOVE_CONTAINER_SCROLL_LISTENER:
			removeContainerScrollListener(action.containerId, action.contentId);
			break;
		case VXMobileActionTypes.RESTORE_NAVBAR_STATE:
			restoreNavbarState(action.path);
			break;
		case VXMobileActionTypes.SCROLL_PAGE_TO:
			scrollPageTo(action.position);
			break;
		case VXMobileActionTypes.SET_RESTORE_NAVBAR_STATE:
			setRestoreNavbarState(action.restore);
			break;
		case VXMobileActionTypes.SET_ROUTE:
			setRoute(action.routeName, action.routeArgs);
			break;
		case VXMobileActionTypes.SET_SCROLL_TO_ON_MOUNT:
			setScrollToOnMount(action.route, action.position);
			break;
		case VXMobileActionTypes.SHOW_NAVBAR:
			showNavbar(action.triggerName, action.callback);
			break;
		case VXMobileActionTypes.STORE_NAVBAR_STATE:
			storeNavbarState(action.path);
			break;
		case VXMobileActionTypes.TOGGLE_MENU:
			toggleMenu(action.menuId, true);
			break;
		case VXMobileActionTypes.TOGGLE_SUBMENU:
			toggleSubMenu(action.menuId, action.subMenuId, true, action.isBlocking);
			break;
		case VXMobileActionTypes.UPDATE_SUBMENU:
			updateSubMenu(action.menuId, action.subMenuId, action.data);
			break;
		case VXMobileActionTypes.CONTENT_SCROLL:
			setContentScroll(action.scrollPosition);
			break;
		default:
	}
});

if (typeof $ !== 'undefined') {
	addEventListener("popstate", () => {
		setRestoreNavbarState(true);
	});
}

export default VXMobileStore;
