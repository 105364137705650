'use strict';

import Dispatcher from './../Dispatcher';
import Constants  from './../Constants';

const ActionTypes = Constants.ActionTypes.Actor;

const ActorActionCreator = {
	loadActorData:       function(actorId) {
		Dispatcher.dispatch({
			type:    ActionTypes.LOAD_DATA,
			actorId: actorId,
		});
	},
	loadActorDataByName: function(actorName) {
		Dispatcher.dispatch({
			type:      ActionTypes.LOAD_DATA,
			actorName: actorName,
		});
	},

	/**
	 * @param {String|Number} actorId
	 * @param {Number}        limit
	 * @param {String|number} fsk
	 */
	loadActorPictures:                 function(actorId, limit = -1, fsk = 'none') {
		Dispatcher.dispatch({
			type:    ActionTypes.LOAD_PICTURES,
			actorId: actorId,
			limit:   limit,
			fsk:     fsk,
		});
	},
	loadFilteredActorVideos:           function(
		actorId,
		count,
		offset,
		orders,
		includeGuestRating,
		tags,
		onlyFreeContent,
		fetchAllVideosCount,
		onlyVXSelectContent,
		onlyClassicContent
	) {
		Dispatcher.dispatch({
			type: ActionTypes.LOAD_VIDEOS,
			actorId,
			count,
			offset,
			orders,
			includeGuestRating,
			tags,
			onlyFreeContent,
			fetchAllVideosCount,
			onlyVXSelectContent,
			onlyClassicContent,
		});
	},
	loadFilteredActorsVideos:          function(
		actorIds,
		count,
		offset,
		orders,
		includeGuestRating,
		tags,
		onlyFreeContent,
		fetchAllVideosCount,
		onlyVXSelectContent
	) {
		Dispatcher.dispatch({
			type: ActionTypes.LOAD_VIDEOS_ACTORS,
			actorIds,
			count,
			offset,
			orders,
			includeGuestRating,
			tags,
			onlyFreeContent,
			fetchAllVideosCount,
			onlyVXSelectContent,
		});
	},
	loadActorVideoTags:                function(actorId) {
		Dispatcher.dispatch({
			type: ActionTypes.LOAD_VIDEO_TAGS,
			actorId,
		});
	},
	loadVideoRecommendationsByActorId: function(actorId, countFromSameActor, countFromDifferentActor) {
		Dispatcher.dispatch({
			type: ActionTypes.LOAD_VIDEO_RECOMMENDATIONS_BY_ACTOR_ID,
			actorId,
			countFromSameActor,
			countFromDifferentActor,
		});
	},
	loadVideoRecommendationsByAlbumId: function(albumId, countFromSameActor, countFromDifferentActor) {
		Dispatcher.dispatch({
			type: ActionTypes.LOAD_VIDEO_RECOMMENDATIONS_BY_ALBUM_ID,
			albumId,
			countFromSameActor,
			countFromDifferentActor,
		});
	},
	loadActorTopVideos:                function(actorId) {
		Dispatcher.dispatch({
			type:    ActionTypes.LOAD_TOP_VIDEOS,
			actorId: actorId,
		});
	},

	loadActorPromotedVideo: function(actorId) {
		Dispatcher.dispatch({
			type:    ActionTypes.LOAD_PROMOTED_VIDEO,
			actorId: actorId,
		});
	},

	loadActorState: function(actorId) {
		Dispatcher.dispatch({
			type:    ActionTypes.LOAD_STATE,
			actorId: actorId,
		});
	},

	loadActorNote: function(actorId) {
		Dispatcher.dispatch({
			type:    ActionTypes.LOAD_NOTE,
			actorId: actorId,
		});
	},

	/**
	 * @param {Number} actorId
	 */
	loadActorChatRatings: function(actorId) {
		Dispatcher.dispatch({
			type: ActionTypes.LOAD_CHAT_RATINGS,
			actorId,
		});
	},

	setPreliminaryData: function(actorData) {
		Dispatcher.dispatch({
			type:      ActionTypes.SET_PRELIMINARY_DATA,
			actorData: actorData,
		});
	},

	setProfilePage: function(pageId) {
		Dispatcher.dispatch({
			type:   ActionTypes.SET_PROFILE_PAGE,
			pageId: pageId,
		});
	},

	/**
	 * @param {Object} chatRatings
	 * @param {Number} actorId
	 */
	actorChatRatingsLoaded: function(chatRatings, actorId) {
		Dispatcher.dispatch({
			type: ActionTypes.LOADED_CHAT_RATINGS,
			chatRatings,
			actorId,
		});
	},

	/**
	 * @param {Number} actorId
	 * @param {String} imageSize
	 * @param {Boolean} placeholder
	 * @param {String} durationFormat
	 */
	loadActorInterviews: function(
		actorId,
		imageSize      = Constants.Interviews.IMAGE_SIZE_720,
		placeholder    = true,
		durationFormat = Constants.Interviews.DURATION_SEC
	) {
		Dispatcher.dispatch({
			type: ActionTypes.LOAD_INTERVIEWS,
			actorId,
			imageSize,
			placeholder,
			durationFormat,
		});
	},

	/**
	 * @param {Array} interviews
	 * @param {Number} actorId
	 * @param {String} imageSize
	 * @param {Boolean} placeholder
	 * @param {String} durationFormat
	 */
	actorInterviewsLoaded: function(
		interviews,
		actorId,
		imageSize      = Constants.Interviews.IMAGE_SIZE_720,
		placeholder    = true,
		durationFormat = Constants.Interviews.DURATION_SEC
	) {
		Dispatcher.dispatch({
			type: ActionTypes.LOADED_INTERVIEWS,
			interviews,
			actorId,
			imageSize,
			placeholder,
			durationFormat,
		});
	},

	/**
	 * @param {Number} actorId
	 * @param {Number} first
	 */
	loadModelRecommendations: function(actorId, first = 4, isExtended = false) {
		Dispatcher.dispatch({
			type: ActionTypes.LOAD_RECOMMENDED_MODELS,
			actorId,
			first,
			isExtended
		});
	},

	/**
	 * @param {Number} actorId
	 * @param {Number} first
	 * @param {Array} recommendations
	 */
	modelRecommendationsLoaded: function(actorId, first = 4, recommendations = []) {
		Dispatcher.dispatch({
			type: ActionTypes.LOADED_RECOMMENDED_MODELS,
			actorId,
			first,
			recommendations,
		});
	},

	loadActorSeoContent:       function(actorId, tagGroup) {
		Dispatcher.dispatch({
			type:    ActionTypes.LOAD_SEO_CONTENT,
			actorId: actorId,
			tagGroup: tagGroup,
		});
	},
};

export default ActorActionCreator;
