'use strict';
/*global VXConfig*/

import Dispatcher                                                                                                from '../Dispatcher';
import FluxEventEmitter                                                                                          from '../FluxEventEmitter';
import {currentYPosition, doFetch, getElementHeight, getElementWidth, isLocalItemEnabled, supportsPassiveEvents} from '../../utils/CommonUtils';
import Constants                                                                                                 from '../Constants';
import assign                                                                                                    from 'object-assign';
import platform                                                                                                  from 'platform';

const ActionTypes = Constants.ActionTypes.Browser;

const ChangeEvents = {
	mobileMenu:       'mobileMenu',
	online:           'online',
	visibilityChange: 'visibilityChange',
	windowResize:     'windowResize',
	windowPopState:   'popstate',
	showStatusBar:    'showStatusBar',
	documentClick:    'click',
	phoneAccess:      'phoneAccess',
	scroll:           'scroll',
	preloadMedia:     'preloadedMedia',
	closeModal:       'closeModal',
};

const MOBILE_MENU_BREAKPOINT = 1023;
const ORIENTATION_PORTRAIT   = 'portrait';
const ORIENTATION_LANDSCAPE  = 'landscape';

const browserData = {
	mobileMenu: false,
};

let _showPhoneAccess     = true;
let _onlineState         = true;
let _orientation         = '';
let _showStatusBar       = false;
const _windowSize        = {
	height: 0,
	width:  0,
};
const preloadedMediaUrls = {};

const browserAbilities = {};

let hasLocalStorage        = true;
const fallbackLocalStorage = {};

function isLocalStorageSupported() {
	const testKey = 'test';
	try {
		const storage = window.localStorage;
		storage.setItem(testKey, '1');
		storage.removeItem(testKey);
		return true;
	} catch (error) {
		return false;
	}
}

function isSexole() {
	return ['sexole', 'sexolecom'].includes(VXConfig.pfmSubref);
}

function updateScreenSize() {

	let orientation = screen.msOrientation || (screen.orientation || screen.mozOrientation || {}).type;

	if (orientation && !orientation.indexOf(ORIENTATION_LANDSCAPE)) {
		orientation = ORIENTATION_LANDSCAPE;
	} else if (orientation && !orientation.indexOf(ORIENTATION_PORTRAIT)) {
		orientation = ORIENTATION_PORTRAIT;
	} else if (typeof window.orientation !== 'undefined') {
		//typeof is used here, because 0 can be a valid value
		if (Math.abs(window.orientation) === 90) {
			orientation = ORIENTATION_LANDSCAPE;
		} else {
			orientation = ORIENTATION_PORTRAIT;
		}
	}

	let height, width;
	if (BrowserStore.isChromeIOS()) { // Workaround for https://bugs.webkit.org/show_bug.cgi?id=170595
		const documentElement = document.documentElement;
		height                = documentElement.clientHeight;
		width                 = documentElement.clientWidth;
	} else {
		height = getElementHeight(document.body);
		width  = getElementWidth(document.body);
	}

	_orientation       = orientation;
	_windowSize.height = height;
	_windowSize.width  = width;
}

function updateViewportWidth() {
	const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0); // Cross-Browser (in jquery width() border etc. is missing)
	if ((width < MOBILE_MENU_BREAKPOINT) && !browserData.mobileMenu) {
		browserData.mobileMenu = true;
		BrowserStore.emitMobileMenuChange(browserData.mobileMenu);
	} else if ((width >= MOBILE_MENU_BREAKPOINT) && browserData.mobileMenu) {
		browserData.mobileMenu = false;
		BrowserStore.emitMobileMenuChange(browserData.mobileMenu);
	}
}

function registerVisibilityChange(callback) {
	// Set the name of the hidden property and the change event for visibility
	let hidden, visibilityChange;
	if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
		hidden           = "hidden";
		visibilityChange = "visibilitychange";
	} else if (typeof document.msHidden !== "undefined") {
		hidden           = "msHidden";
		visibilityChange = "msvisibilitychange";
	} else if (typeof document.webkitHidden !== "undefined") {
		hidden           = "webkitHidden";
		visibilityChange = "webkitvisibilitychange";
	}

	document.addEventListener(visibilityChange, () => {
		callback(!document[hidden]);
	}, false);
}

function setShowStatusBar(showStatusBar) {
	if (_showStatusBar !== showStatusBar) {
		_showStatusBar = showStatusBar;
		BrowserStore.emitShowStatusBarChange(showStatusBar);
	}
}

function updateOnlineState() {
	_onlineState = 'onLine' in navigator ? navigator.onLine : true;
	BrowserStore.emitOnlineChange();
}

function preloadMedia(url, useAuthentication) {
	if (!preloadedMediaUrls[url]) {
		doFetch(url, {}, Constants.HttpMethods.GET, false, {}, true, useAuthentication).then((blob) => {
			preloadedMediaUrls[url] = URL.createObjectURL(blob);

			BrowserStore.emitPreloadedMediaChange(url);
		});
	}
}

const BrowserStore = assign({}, FluxEventEmitter.prototype, {

	addShouldModalWindowCloseListener: function(callback) {
		this.on(ChangeEvents.closeModal, callback);
	},

	emitPreloadedMediaChange: function(url) {
		this.emit(ChangeEvents.preloadMedia, url);
	},

	emitShowStatusBarChange: function(showStatusBar) {
		this.emit(ChangeEvents.showStatusBar, showStatusBar);
	},

	emitMobileMenuChange: function(mobileMenu) {
		this.emit(
			ChangeEvents.mobileMenu,
			{
				mobileMenu: mobileMenu,
			}
		);
	},

	emitOnlineChange: function() {
		this.emit(ChangeEvents.online, !!_onlineState);
	},

	emitWindowResize: function() {
		this.emit(ChangeEvents.windowResize);
	},

	emitVisiblityChange: function(visible) {
		this.emit(
			ChangeEvents.visibilityChange, visible
		);
	},

	setShowPhoneAccess(show) {
		if (typeof show === 'boolean') {
			_showPhoneAccess = show;
			this.emitShowPhoneAccessChange();
		}
	},

	getShowPhoneAccess() {
		return _showPhoneAccess;
	},

	addPreloadedMediaChangeListener: function(callback) {
		this.on(ChangeEvents.preloadMedia, callback);
	},

	removePreloadedMediaChangeListener: function(callback) {
		this.removeListener(ChangeEvents.preloadMedia, callback);
	},

	addShowPhoneAccessChangeListener: function(callback) {
		this.on(ChangeEvents.phoneAccess, callback);
	},

	removeShowPhoneAccessChangeListener: function(callback) {
		this.removeListener(ChangeEvents.phoneAccess, callback);
	},

	emitShowPhoneAccessChange: function() {
		this.emit(ChangeEvents.phoneAccess, _showPhoneAccess);
	},

	addShowStatusBarChangeListener: function(callback) {
		this.on(ChangeEvents.showStatusBar, callback);
	},

	addStatusBarWindowClickListener: function(callback) {
		document.addEventListener(ChangeEvents.documentClick, callback, false);
	},

	removeShowStatusBarChangeListener: function(callback) {
		this.removeListener(ChangeEvents.showStatusBar, callback);
	},

	removeShowStatusBarWindowClickListener: function(callback) {
		document.removeEventListener(ChangeEvents.documentClick, callback, false);
	},

	addMobileMenuChangeListener: function(callback) {
		this.on(ChangeEvents.mobileMenu, callback);
	},

	addWindowResizeListener: function(callback) {
		this.on(ChangeEvents.windowResize, callback);
	},

	removeWindowResizeListener: function(callback) {
		this.removeListener(ChangeEvents.windowResize, callback);
	},

	removeMobileMenuChangeListener: function(callback) {
		this.removeListener(ChangeEvents.mobileMenu, callback);
	},

	addOnlineChangeListener: function(callback) {
		this.on(ChangeEvents.online, callback);
	},

	removeOnlineChangeListener: function(callback) {
		this.removeListener(ChangeEvents.online, callback);
	},

	addVisibilityChangeListener: function(callback) {
		this.on(ChangeEvents.visibilityChange, callback);
	},

	removeVisibilityChangeListener: function(callback) {
		this.removeListener(ChangeEvents.visibilityChange, callback);
	},

	getPreloadedMediaUrl: function(url) {
		return preloadedMediaUrls[url] ? preloadedMediaUrls[url] : null;
	},

	onWindowResize: function() {
		updateScreenSize();
		updateViewportWidth();
		this.emitWindowResize();
	},

	isMobileMenuVisible: function() {
		return browserData.mobileMenu;
	},

	getLocalItem: function(key) {
		if (hasLocalStorage) {
			return window.localStorage ? window.localStorage.getItem(key) : null;
		}
		return typeof fallbackLocalStorage[key] !== 'undefined' ? fallbackLocalStorage[key] : null;
	},

	setLocalItem: function(key, data) {
		if (hasLocalStorage && isLocalItemEnabled(key)) {
			window.localStorage ? window.localStorage.setItem(key, data) : null;
		} else {
			fallbackLocalStorage[key] = data;
		}
	},

	removeItem: function(key) {
		if (hasLocalStorage) {
			window.localStorage.removeItem(key);
		} else {
			fallbackLocalStorage[key] = null;
		}
	},

	getConnection: function() {
		return navigator.connection || navigator.mozConnection || navigator.webkitConnection || navigator.msConnection;
	},

	/**
	 * @return {{width: number, height: number}}
	 */
	getWindowSize: function() {
		return _windowSize;
	},

	getViewPort() {
		const windowWidth = this.getWindowSize().width;
		if (windowWidth < Constants.Grid2.BREAKPOINT_MIN_WIDTHS.sm) {
			return Constants.Grid2.BREAKPOINTS.XS;
		} else if (windowWidth < Constants.Grid2.BREAKPOINT_MIN_WIDTHS.md) {
			return Constants.Grid2.BREAKPOINTS.SM;
		} else if (windowWidth < Constants.Grid2.BREAKPOINT_MIN_WIDTHS.lg) {
			return Constants.Grid2.BREAKPOINTS.MD;
		} else if (windowWidth < Constants.Grid2.BREAKPOINT_MIN_WIDTHS.xl) {
			return Constants.Grid2.BREAKPOINTS.LG;
		}
		return Constants.Grid2.BREAKPOINTS.XL;
	},

	historyPushState: function(stateObj, title, url) {
		if (window.history.pushState) {
			stateObj.pjaxStateOptions           = stateObj.pjaxStateOptions || {};
			stateObj.pjaxStateOptions.scrollTop = currentYPosition();

			if (url) {
				window.history.pushState(stateObj, title, url);
			} else {
				window.history.pushState(stateObj, title);
			}
		}
	},

	historyReplaceState: function(stateObj, title, url) {
		if (window.history.replaceState) {
			stateObj.pjaxStateOptions           = stateObj.pjaxStateOptions || {};
			stateObj.pjaxStateOptions.scrollTop = currentYPosition();

			if (url) {
				window.history.replaceState(stateObj, title, url);
			} else {
				window.history.replaceState(stateObj, title);
			}
		}
	},

	isAndroid: function() {
		return /Android/.test(navigator.appVersion);
	},

	/**
	 * Checks if the current browser is of given name and at least given version on a certain OS
	 *
	 * @param name string
	 * @param (version) int
	 * @param (os) string
	 * @param (versionTo) int - up to this version of the browser (excluded)
	 * @returns {boolean}
	 */
	isBrowserVersion: function(name, version, os, versionTo) {
		os        = os || null;
		version   = version || 0;
		versionTo = versionTo || 0;

		if (os) {
			os = os.toLowerCase();
		}

		name = name.toLowerCase();

		let platformName = null;
		let platformOS   = null;

		// platform.name could be null (e.g. for T-Online Browser)
		if (platform.name) {
			platformName = platform.name.toLowerCase();

			if (platformName.length > name.length) {
				platformName = platformName.substr(0, name.length);
			}
		}

		if (os && platform.os && platform.os.family) {
			platformOS = platform.os.family.toLowerCase();

			if (platformOS.length > os.length) {
				platformOS = platformOS.substr(0, os.length);
			}
		}

		const isOS        = os === null || platformOS === os;
		const isName      = platformName === name;
		const isVersion   = parseInt(platform.version) >= parseInt(version);
		const isVersionTo = versionTo === 0 || parseInt(platform.version) < parseInt(versionTo);

		return isOS && isName && isVersion && isVersionTo;
	},

	isChromeIOS: function() {
		return this.isBrowserVersion(Constants.Browsers.Name.CHROME, 0, Constants.Browsers.OS.IOS);
	},

	isFirefoxIOS: function() {
		return this.isBrowserVersion(Constants.Browsers.Name.FIREFOX, 0, Constants.Browsers.OS.IOS);
	},

	isIE: function() {
		return this.isBrowserVersion(Constants.Browsers.Name.IE);
	},

	isEdge: function() {
		return this.isBrowserVersion(Constants.Browsers.Name.EDGE);
	},

	isLandscape: function() {
		return _orientation === ORIENTATION_LANDSCAPE;
	},

	isOnline: function() {
		return _onlineState === true;
	},

	isPortrait: function() {
		return _orientation === ORIENTATION_PORTRAIT;
	},

	isSafariMobile: function() {
		return this.isBrowserVersion(Constants.Browsers.Name.SAFARI, 0, Constants.Browsers.OS.IOS);
	},

	showsBlankFlashArea: function() {
		return this.isBrowserVersion(Constants.Browsers.Name.FIREFOX, 58);
	},

	supportsFlexbox() {
		const doc           = document.body || document.documentElement;
		const style         = doc.style;
		let isFlexSupported = false;

		if (style.flexWrap === '') {
			isFlexSupported = true;
		}

		return isFlexSupported;
	},

	supportsHlsNative: function() {
		let supports;
		if (typeof browserAbilities.hlsNative !== 'undefined') {
			supports = browserAbilities.hlsNative;
		} else {
			/*
			 * currently limited to browsers with support for autoplay
			 * - Safari >= 10 (muted autoplay)
			 * - Chrome >= 53 (on iOS only)
			 *
			 */
			supports                   = this.isBrowserVersion(Constants.Browsers.Name.SAFARI, 10) ||
				this.isBrowserVersion(Constants.Browsers.Name.CHROME, 53, Constants.Browsers.OS.IOS);
			browserAbilities.hlsNative = supports;
		}

		return supports;
	},

	supportsHtmlUpstreamOnly: function() {
		let supports;
		if (typeof browserAbilities.htmlUpstreamOnly !== 'undefined') {
			supports = browserAbilities.htmlUpstreamOnly;
		} else {
			supports                          = this.isBrowserVersion(Constants.Browsers.Name.CHROME, 64) ||
				this.isBrowserVersion(Constants.Browsers.Name.FIREFOX, 57);
			browserAbilities.htmlUpstreamOnly = supports;
		}

		return supports;
	},

	supportsUpstream: function() {
		let supports;
		if (typeof browserAbilities.upstream !== 'undefined') {
			supports = browserAbilities.upstream;
		} else {
			/*
			 * only enable upstream for browsers that support userMedia (not yet supported by Safari, IE)
			 */
			supports                  = !!((navigator.mediaDevices && navigator.mediaDevices.getUserMedia) || navigator.getUserMedia || navigator.webkitGetUserMedia);
			browserAbilities.upstream = supports;
		}

		return supports;
	},

	addWindowPopStateListener: function(callback) {
		if (hasLocalStorage) {
			window.addEventListener(ChangeEvents.windowPopState, callback);
		}
	},

	removeWindowPopStateListener: function(callback) {
		if (hasLocalStorage) {
			window.removeEventListener(ChangeEvents.windowPopState, callback);
		}
	},

	/**
	 * @param {Function} callback
	 * @param {Boolean} passive
	 */
	addWindowScrollListener: function(callback, passive = false) {
		if (hasLocalStorage) {
			const options = passive && supportsPassiveEvents() ? {passive: true} : false;
			window.addEventListener(ChangeEvents.scroll, callback, options);
		}
	},

	/**
	 * @param {Function} callback
	 * @param {Boolean} passive
	 */
	removeWindowScrollListener: function(callback, passive = false) {
		if (hasLocalStorage) {
			const options = passive && supportsPassiveEvents() ? {passive: true} : false;
			window.removeEventListener(ChangeEvents.scroll, callback, options);
		}
	},

	/**
	 * @return {Location | string | any}
	 */
	getLocation: function() {
		return window.location;
	},

	isTablet() {
		return VXConfig.device && (VXConfig.device.isTablet || (!VXConfig.device.isMobile && navigator.platform === "MacIntel" && navigator.maxTouchPoints > 0));
	},

	isSexole() {
		return isSexole();
	},

	isSpanishPfm() {
		return isSexole() || VXConfig.language === Constants.Languages.ES;
	},

	getAvsPictureUrl() {
		return VXConfig.avsPictureUrl;
	},

	isTouch() {
		return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
	},

	isMobileView() {
		return window.innerWidth < Constants.Grid2.BREAKPOINT_MIN_WIDTHS.sm;
	},
	
	isTabletView() {
		return window.innerWidth < Constants.Grid2.BREAKPOINT_MIN_WIDTHS.md;
	},
});

BrowserStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.SET_SHOW_STATUS_BAR:
			setShowStatusBar(!!action.showStatusBar);
			break;
		case ActionTypes.PRELOAD_MEDIA:
			preloadMedia(action.url, action.useAuthentication);
			break;
		case ActionTypes.CLOSE_MODAL:
			BrowserStore.emit(ChangeEvents.closeModal);
			break;
		default:
	}
});

// init
updateScreenSize();
updateViewportWidth();

addEventListener("resize", BrowserStore.onWindowResize.bind(BrowserStore));
addEventListener("orientationchange", BrowserStore.onWindowResize.bind(BrowserStore));
addEventListener("online", updateOnlineState);
addEventListener("offline", updateOnlineState);

registerVisibilityChange(BrowserStore.emitVisiblityChange.bind(BrowserStore));
hasLocalStorage = isLocalStorageSupported();
updateOnlineState();

export default BrowserStore;
