'use strict';
/* globals VXConfig */

import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import FluxEventEmitter from '../FluxEventEmitter';
import Routes           from '../../utils/Routes';
import assign                          from 'object-assign';
import {doFetch, getCookie, setCookie} from "../../utils/CommonUtils";
import Flux                            from "../Flux";

const ConversionActionTypes = Constants.ActionTypes.Conversion;
const CHANGE_EVENTS         = {
	INCENTIVES:           'incentives',
	REFILL_WINDOW_HIDDEN: 'refillWindowHidden',
	CLOSE:                'close',
};

const _incentives = {};
let _vxpayConfig  = {};
let _trackingData = [];

function addIncentiveToRegion(incentive) {
	const region = incentive.region;
	if (region) {
		if (typeof _incentives[region] === 'undefined') {
			_incentives[region] = [];
		}

		// delete all incentives of this type that are already in the region
		deleteIncentivesByAttribute(_incentives[region], 'type', incentive.type);

		// add incentive to region
		_incentives[region].push(incentive);
	}
}

function addIncentives(incentives) {
	for (let i = 0; i < incentives.length; i++) {
		addIncentiveToRegion(incentives[i]);
	}
}

function setVXPayConfig(vxpayConfig = {}) {
	_vxpayConfig = vxpayConfig;
}

function setTrackingData(trackingData = []) {
	_trackingData = trackingData;
}

function clearRegion(region) {
	_incentives[region] = [];
	ConversionStore.emit(CHANGE_EVENTS.INCENTIVES, Constants.ConversionEventTypes.CLEAR, region);
}

function closeIncentive(incentive, data) {
	deleteIncentive(incentive);
	redeemIncentive(incentive, data)
		.finally(() => {
			ConversionStore.emit(CHANGE_EVENTS.CLOSE);
		});
}

function compareIncentives(incentiveA, incentiveB) {
	const prioA = typeof incentiveA.priority !== 'undefined' ? incentiveA.priority : 0;
	const prioB = typeof incentiveB.priority !== 'undefined' ? incentiveB.priority : 0;

	return prioA > prioB ? -1 : (prioA < prioB ? 1 : 0);
}

function deleteIncentive(incentive) {
	if (incentive && incentive.region) {
		const incentives = _incentives[incentive.region];

		if (incentives) {
			let i = incentives.length - 1;
			while (i >= 0) {
				if (incentives[i] === incentive) {
					incentives.splice(i, 1);
					break;
				} else {
					i--;
				}
			}
		}
	}
}

function redeemIncentive(incentive, data = {}) {
	const payload = {result: data, incentive};

	return doFetch(Routes.getRoute(Routes.Names.CONVERSION_CLOSE_INCENTIVE, {}), payload, Constants.HttpMethods.POST, false).then(function(result) {
		if (result?.data.success) {
			loadPageLoadIncentives(VXConfig.routeName, VXConfig.routeArgs);
		}
	});
}

function deleteIncentivesByAttribute(incentives, attribute, value) {
	let i = incentives.length - 1;
	while (i >= 0) {
		if (incentives[i][attribute] === value) {
			incentives.splice(i, 1);
		}
		i--;
	}
}

function deleteIncentivesByEvent(event) {
	for (const r in _incentives) {
		if (Object.hasOwn(_incentives, r)) {
			deleteIncentivesByAttribute(_incentives[r], 'event', event);
		}
	}
}

function getIncentiveByRegion(region) {
	const incentives = getIncentivesByRegion(region);
	return incentives.length > 0 ? prepareIncentive(incentives[0]) : null;
}

function getIncentivesByRegion(region) {
	let incentives = [];
	if (typeof _incentives[region] !== 'undefined' && _incentives[region].length > 0) {
		// sort incentives by priority
		_incentives[region].sort(compareIncentives);

		incentives = _incentives[region];
	}

	return incentives;
}

function prepareIncentive(incentive) {
	if (typeof incentive.countdown !== 'undefined' && incentive.countdown > 0) {
		const date = new Date();
		if (typeof incentive.countdownStartTime !== 'undefined') {
			incentive.countdown = Math.max(0, incentive.countdown - Math.round((date - incentive.countdownStartTime) / 1000));
		}

		incentive.countdownStartTime = date;
	}

	return incentive;
}

const ConversionStore = assign({}, FluxEventEmitter.prototype, {
	addIncentivesChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.INCENTIVES, callback);
	},

	removeIncentivesChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.INCENTIVES, callback);
	},

	addRefillWindowHiddenChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.REFILL_WINDOW_HIDDEN, callback);
	},

	removeRefillWindowHiddenChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.REFILL_WINDOW_HIDDEN, callback);
	},

	addIncentiveCloseListener: function(callback) {
		this.on(CHANGE_EVENTS.CLOSE, callback);
	},

	removeIncentiveCloseListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.CLOSE, callback);
	},

	getExitIntentIncentive() {
		const incentives = getIncentivesByRegion(Constants.ConversionIncentiveRegions.EXIT_INTENT);
		let incentive    = null;
		if (incentives.length > 0) {
			incentive = prepareIncentive(incentives.shift());
		}

		return incentive;
	},

	getIncentiveByRegion(region) {
		return getIncentiveByRegion(region);
	},

	clearRegion(region) {
		clearRegion(region);
	},

	getVXPayConfig() {
		return _vxpayConfig;
	},

	setVXPayConfig(vxpayConfig = {}) {
		setVXPayConfig(vxpayConfig);
	},

	getTrackingData() {
		return _trackingData;
	},

	blockIncentive(incentive, data = {}) {
		let oldVal  = {};
		try {
			oldVal = JSON.parse(getCookie(Flux.Constants.CookieNames.BLOCK_INCENTIVE_GROUP) || '{}');
		} catch (e) {
			// do nothing
		}

		oldVal[incentive.uniqueGroup] = {...data, uniqueGroup: incentive.uniqueGroup, event: incentive.event, type: incentive.type};
		const json = JSON.stringify(oldVal);
		setCookie(Flux.Constants.CookieNames.BLOCK_INCENTIVE_GROUP, json, 30, '/', '', true, Flux.Constants.CookieSameSiteAttributes.LAX);
	},


	isIncentiveBlocked(incentive) {
		let blockedGroups  = {};
		try {
			blockedGroups = JSON.parse(getCookie(Flux.Constants.CookieNames.BLOCK_INCENTIVE_GROUP) || '{}');
		} catch (e) {
			// do nothing
		}

		return Boolean(blockedGroups[incentive.uniqueGroup]);
	}
});

function loadBuyImageAlbumIncentives(albumId) {
	doFetch(Routes.getRoute(Routes.Names.CONVERSION_GET_BUY_IMAGE_ALBUM_INCENTIVES, {'albumId': albumId}), {}, Constants.HttpMethods.GET, true).then(function(result) {
		deleteIncentivesByEvent(Constants.ConversionEventTypes.BUY_IMAGE_ALBUM_OPENED);

		if (result.success) {
			addIncentives(result.data.incentives);
			ConversionStore.emit(CHANGE_EVENTS.INCENTIVES, Constants.ConversionEventTypes.BUY_IMAGE_ALBUM_OPENED, albumId);
		} else {
			ConversionStore.emit(CHANGE_EVENTS.INCENTIVES, Constants.ConversionEventTypes.BUY_IMAGE_ALBUM_OPENED, albumId);
		}
	});
}

function loadIncentivesByEvent(event) {
	doFetch(Routes.getRoute(Routes.Names.CONVERSION_GET_INCENTIVES_BY_EVENT), {event: event}, Constants.HttpMethods.GET, true).then(function(result) {
		deleteIncentivesByEvent(event);

		if (result.success) {
			addIncentives(result.data.incentives);
			ConversionStore.emit(CHANGE_EVENTS.INCENTIVES, event);
		} else {
			ConversionStore.emit(CHANGE_EVENTS.INCENTIVES, event);
		}
	});
}

function loadPageLoadIncentives(routeName, routeArgs) {
	let payload = null;
	if (routeName && routeArgs) {
		payload = {
			'routeName': routeName,
			'routeArgs': JSON.stringify(routeArgs),
		};
	}

	doFetch(Routes.getRoute(Routes.Names.CONVERSION_GET_PAGE_LOAD_INCENTIVES), payload, Constants.HttpMethods.POST, true).then(function(result) {
		deleteIncentivesByEvent(Constants.ConversionEventTypes.PAGE_LOAD);

		if (result.success) {
			addIncentives(result.data.incentives);
			setVXPayConfig(result.data.vxpayConfig);

			setTrackingData(result.data.trackingData);
			ConversionStore.emit(CHANGE_EVENTS.INCENTIVES, Constants.ConversionEventTypes.PAGE_LOAD);
		} else {
			ConversionStore.emit(CHANGE_EVENTS.INCENTIVES, Constants.ConversionEventTypes.PAGE_LOAD);
		}
	});
}

function loadVideoPreviewEndIncentives(albumId) {
	doFetch(Routes.getRoute(Routes.Names.CONVERSION_GET_VIDEO_PREVIEW_END_INCENTIVES, {'albumId': albumId}), {}, Constants.HttpMethods.GET, true).then(function(result) {
		deleteIncentivesByEvent(Constants.ConversionEventTypes.VIDEO_PREVIEW_ENDED);

		if (result.success) {
			addIncentives(result.data.incentives);
			ConversionStore.emit(CHANGE_EVENTS.INCENTIVES, Constants.ConversionEventTypes.VIDEO_PREVIEW_ENDED, albumId);
		} else {
			ConversionStore.emit(CHANGE_EVENTS.INCENTIVES, Constants.ConversionEventTypes.VIDEO_PREVIEW_ENDED, albumId);
		}
	});
}

function setGuestAlreadySignedUp(alreadySignedUp) {
	if (VXConfig.routeName === 'Lindemann' && !Flux.Guest.isLoggedIn()) {
		return;
	}

	if (!alreadySignedUp) {
		loadPageLoadIncentives(VXConfig.routeName, VXConfig.routeArgs);
	} else {
		doFetch(Routes.getRoute(Routes.Names.CONVERSION_SET_GUEST_INFO), {
			type:  Constants.ConversionGuestInfoTypes.GUEST_ALREADY_SIGNED_UP,
			value: true,
		}, Constants.HttpMethods.POST, true).then(function() {
			loadPageLoadIncentives(VXConfig.routeName, VXConfig.routeArgs);
		});
	}
}

function setGuestInfo(type, data) {
	if (VXConfig.routeName === 'Lindemann' && !Flux.Guest.isLoggedIn()) {
		return;
	}

	doFetch(Routes.getRoute(Routes.Names.CONVERSION_SET_GUEST_INFO), {
		type:  type,
		value: JSON.stringify(data),
	}, Constants.HttpMethods.POST, true).then(function() {
		loadPageLoadIncentives(VXConfig.routeName, VXConfig.routeArgs);
	});
}

function setConversionRefillWindowHidden(incentiveType, interval, incentiveRegion) {
	doFetch(Routes.getRoute(Routes.Names.CONVERSION_SET_REFILL_WINDOW_HIDDEN), {
		incentiveType: incentiveType,
		interval:      interval,
	}, Constants.HttpMethods.POST, true).then(function(result) {
		if (_incentives[incentiveRegion]) {
			deleteIncentivesByAttribute(_incentives[incentiveRegion], 'type', incentiveType);
		}
		ConversionStore.emit(CHANGE_EVENTS.REFILL_WINDOW_HIDDEN, result.data.success, incentiveType);
	});
}


/**
 * Dispatch incoming requests
 */
ConversionStore.dispatchToken = Dispatcher.register((action) => {
	switch (action.type) {
		case ConversionActionTypes.CLOSE_INCENTIVE:
			closeIncentive(action.incentive, action.data);
			break;
		case ConversionActionTypes.LOAD_BUY_IMAGE_ALBUM_INCENTIVES:
			loadBuyImageAlbumIncentives(action.albumId);
			break;
		case ConversionActionTypes.LOAD_INCENTIVES_BY_TYPE:
			loadIncentivesByEvent(action.event);
			break;
		case ConversionActionTypes.LOAD_PAGE_LOAD_INCENTIVES:
			loadPageLoadIncentives(action.routeName, action.routeArgs);
			break;
		case ConversionActionTypes.LOAD_VIDEO_PREVIEW_END_INCENTIVES:
			loadVideoPreviewEndIncentives(action.albumId);
			break;
		case ConversionActionTypes.SET_GUEST_ALREADY_SIGNED_UP:
			setGuestAlreadySignedUp(action.alreadySignedUp);
			break;
		case ConversionActionTypes.SET_GUEST_INFO:
			setGuestInfo(action.infoType, action.data);
			break;
		case ConversionActionTypes.SET_CONVERSION_REFILL_WINDOW_HIDDEN:
			setConversionRefillWindowHidden(action.incentiveType, action.interval, action.incentiveRegion);
			break;
		default:
	}
});

export default ConversionStore;
