import Dispatcher               from './../Dispatcher';
import {default as ActionTypes} from './Types/VideoFilterActionTypes';

class VideoFilterActionCreator {
	/**
	 * @param {Number} gridId
	 * @param {Number|String} videoType
	 */
	static setType(gridId, videoType) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_TYPE,
			gridId,
			videoType,
		});
	}

	/**
	 * @param {Number} gridId
	 * @param {String} sort
	 */
	static setSort(gridId, sort) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_SORT,
			gridId,
			sort,
		});
	}

	/**
	 * @param {Number} gridId
	 * @param {Number} from
	 */
	static setDurationFrom(gridId, from) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_DURATION_FROM,
			gridId,
			from,
		});
	}

	/**
	 * @param {Number} gridId
	 * @param {Number} to
	 */
	static setDurationTo(gridId, to) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_DURATION_TO,
			gridId,
			to,
		});
	}

	/**
	 * @param {Number} gridId
	 * @param {Number} from
	 * @param {Number} to
	 */
	static setDuration(gridId, from, to) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_DURATION,
			gridId,
			from,
			to,
		});
	}

	/**
	 * @param {Number} gridId
	 * @param {Number} gender
	 */
	static toggleGender(gridId, gender) {
		Dispatcher.dispatch({
			type: ActionTypes.TOGGLE_GENDER,
			gridId,
			gender,
		});
	}

	/**
	 * @param {Number} gridId
	 */
	static clearTags(gridId) {
		Dispatcher.dispatch({
			type: ActionTypes.CLEAR_TAGS,
			gridId,
		});
	}

	/**
	 * @param {Number} gridId
	 * @param {String} tag
	 */
	static toggleTag(gridId, tag) {
		Dispatcher.dispatch({
			type: ActionTypes.TOGGLE_TAG,
			gridId,
			tag,
		});
	}

	/**
	 * @param {Number} gridId
	 */
	static toggleIsRecommended(gridId) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_IS_RECOMMENDED,
			gridId,
		});
	}

	/**
	 * @param {Number} gridId
	 */
	static toggleIncludeBoughtVideos(gridId) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_INCLUDE_BOUGHT_VIDEOS,
			gridId,
		});
	}

	/**
	 * @param {Number} gridId
	 */
	static toggleFreeVideosOnly(gridId) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_FREE_VIDEOS_ONLY,
			gridId,
		});
	}

	/**
	 * @param {Number} gridId
	 */
	static toggleIncludeFreeVideos(gridId) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_INCLUDE_FREE_VIDEOS,
			gridId,
		});
	}

	/**
	 * @param {Number} gridId
	 */
	static toggleIncludeOnSaleVideos(gridId) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_INCLUDE_ON_SALE_VIDEOS,
			gridId,
		});
	}

	/**
	 * @param {Number} gridId
	 */
	static toggleIncludeVIPVideos(gridId) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_INCLUDE_VIP_VIDEOS,
			gridId,
		});
	}

	/**
	 * @param {Number|String} gridId
	 * @param {Number} page
	 */
	static setPage(gridId, page) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_PAGE,
			gridId,
			page,
		});
	}

	/**
	 * @param {Number|String} gridId
	 * @param {Number} pageSize
	 */
	static setPageSize(gridId, pageSize) {
		Dispatcher.dispatch({
			type: ActionTypes.SET_PAGE_SIZE,
			gridId,
			pageSize,
		});
	}
}

export default VideoFilterActionCreator;
