'use strict';

import Dispatcher                         from '../Dispatcher';
import Constants                          from '../Constants';
import Routes                             from '../../utils/Routes';
import {doFetch, getUrlParams, mergeDeep} from '../../utils/CommonUtils';
import FluxEventEmitter                   from '../FluxEventEmitter';
import assign                             from 'object-assign';
import Flux                               from '../Flux';

const ActorActionTypes               = Constants.ActionTypes.Actor;
const CHANGE_EVENTS                  = {
	ActorData:                     'actorDataChange',
	ActorDataNoResult:             'actorDataNoResult',
	ActorNote:                     'actorNoteChange',
	ActorPictures:                 'actorPicturesChange',
	ActorVideos:                   'actorVideosChange',
	ActorVideoTags:                'actorVideoTagsChange',
	ActorPromotedVideo:            'actorPromotedVideoChange',
	ActorTopVideos:                'actorTopVideosChange',
	VideoRecommendationsByActorId: 'videoRecommendationsByActorIdChange',
	VideoRecommendationsByAlbumId: 'videoRecommendationsByAlbumIdChange',
	ActorChatRatings:              'actorChatRatingsChange',
	ProfilePage:                   'profilePageChange',
	ActorInterviews:               'actorInterviews',
	ActorRecommendedActors:        'actorRecommendedActors',
	OnlineState:                   'onlineState',
	ActorSeoContent:               'actorSeoContentChange',
};
const _actorData                     = {};
const _actorMapNameToId              = {};
const _actorNotes                    = {};
const _actorPictures                 = {};
const _actorPromotedVideos           = {};
const _actorTopVideos                = {};
const _actorFilteredVideoData        = {};
const _actorVideoCounts              = {};
const _actorVideoTags                = {};
const _actorRecommendedActors        = [];
const _videoRecommendationsByActorId = {};
const _videoRecommendationsByAlbumId = {};
let _actorInterviews                 = {};
const _preliminaryActorData          = {};
const _actorChatRatings              = {};
const _actorHeatMaps                 = {};
let _profilePage                     = null;
let _actorDataNoResult               = false;
const _actorNotesLoading             = {};
const _actorSeoContent               = {};
const _videoRecommendationsPromises  = {
	// Note that number of asked videos is ignored here
	album: {
		sameActorIds:      {}, // :: albumId -> Promise
		differentActorIds: {},
	},
	actor: {
		sameActorIds:      {},
		differentActorIds: {},
	},
	/**
	 * @param {number} actorId
	 * @param {number} countFromSameActor
	 * @param {number} countFromDifferentActor
	 * @return {boolean}
	 */
	existsByActorId: function(actorId, countFromSameActor, countFromDifferentActor) {
		return (countFromSameActor > 0 && countFromDifferentActor === 0 && !!this.actor.sameActorIds[actorId])
			|| (countFromDifferentActor > 0 && countFromSameActor === 0 && !!this.actor.differentActorIds[actorId]);
	},

	/**
	 * @param {number} actorId
	 * @param {number} countFromSameActor
	 * @param {number} countFromDifferentActor
	 * @param {Promise} promise
	 */
	setByActorId: function(actorId, countFromSameActor, countFromDifferentActor, promise) {
		if (countFromSameActor > 0 && countFromDifferentActor === 0) {
			if (!this.actor.sameActorIds[actorId]) {
				this.actor.sameActorIds[actorId] = promise;

				promise.finally(() => {
					delete this.actor.sameActorIds[actorId];
				});
			}
			return;
		}

		if (countFromDifferentActor > 0 && countFromSameActor === 0 && !this.actor.differentActorIds[actorId]) {
			this.actor.differentActorIds[actorId] = promise;
			promise.finally(() => {
				delete this.actor.sameActorIds[actorId];
			});
		}
	},


	/**
	 * @param {number} albumId
	 * @param {number} countFromSameActor
	 * @param {number} countFromDifferentActor
	 * @return {boolean}
	 */
	existsByAlbumId: function(albumId, countFromSameActor, countFromDifferentActor) {
		return (countFromSameActor > 0 && countFromDifferentActor === 0 && !!this.album.sameActorIds[albumId])
			|| (countFromDifferentActor > 0 && countFromSameActor === 0 && !!this.album.differentActorIds[albumId]);
	},

	/**
	 * @param {number} albumId
	 * @param {number} countFromSameActor
	 * @param {number} countFromDifferentActor
	 * @param {Promise} promise
	 */
	setByAlbumId: function(albumId, countFromSameActor, countFromDifferentActor, promise) {
		if (countFromSameActor > 0 && countFromDifferentActor === 0) {
			if (!this.album.sameActorIds[albumId]) {
				this.album.sameActorIds[albumId] = promise;
				promise.finally(() => {
					delete this.album.sameActorIds[albumId];
				});
			}
			return;
		}

		if (countFromDifferentActor > 0 && countFromSameActor === 0 && !this.album.differentActorIds[albumId]) {
			this.album.differentActorIds[albumId] = promise;
			promise.finally(() => {
				delete this.album.differentActorIds[albumId];
			});
		}
	},
};


const ActorStore = assign({}, FluxEventEmitter.prototype, {

	getActorData: function(actorId) {
		let actorData = null;
		if (typeof _actorData[actorId] !== 'undefined' && _actorData[actorId] !== null) {
			actorData = _actorData[actorId];
		}

		return actorData;
	},

	getActorDataByName: function(actorName) {
		let actorId = null;

		if (actorName) {
			actorName = actorName.toLowerCase();
		}

		if (typeof _actorMapNameToId[actorName] !== 'undefined' && _actorMapNameToId[actorName] !== null) {
			actorId = _actorMapNameToId[actorName];
		}

		return this.getActorData(actorId);
	},

	getActorDataNoResult: function() {
		return _actorDataNoResult;
	},

	getActorNote: function(actorId) {
		let actorNote = null;
		if (typeof _actorNotes[actorId] !== 'undefined' && _actorNotes[actorId] !== null) {
			actorNote = _actorNotes[actorId];
		}

		return actorNote;
	},

	/**
	 * @param {String|Number} actorId
	 * @param {String|Number} fsk
	 * @return {string}
	 */
	getCacheKey(actorId, fsk) {
		return actorId + ':' + fsk;
	},

	/**
	 * @param {Number} actorId
	 * @param {Number} limit
	 * @param {String|Number} fsk
	 */
	getActorPictures: function(actorId, limit = -1, fsk = 'none') {
		let actorPictures = null;
		const key         = this.getCacheKey(actorId, fsk);

		if (typeof _actorPictures[key] !== 'undefined'
			&& _actorPictures[key] !== null
		) {
			actorPictures = _actorPictures[key];
		}

		if (null !== actorPictures
			&& limit > 0
			&& limit < actorPictures.length
		) {
			actorPictures = actorPictures.slice(0, limit);
		}

		return actorPictures;
	},

	getActorTopVideosByName: function(actorName) {
		let actorVideos = null;
		let actorId     = null;

		if (actorName) {
			actorName = actorName.toLowerCase();
		}

		if (typeof _actorMapNameToId[actorName] !== 'undefined' && _actorMapNameToId[actorName] !== null) {
			actorId = _actorMapNameToId[actorName];
		}

		if (typeof _actorTopVideos[actorId] !== 'undefined' && _actorTopVideos[actorId] !== null) {
			actorVideos = _actorTopVideos[actorId];
		}

		return actorVideos;
	},

	getFilteredActorVideos: function(actorId, sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent) {
		return getFilteredActorVideoDataProperty(actorId, sortOrder, tags, onlyFreeContent, includeGuestRating, 'items', onlyVXSelectContent, onlyClassicContent);
	},

	getFilteredActorVideosTotalCount: function(
		actorId,
		sortOrder,
		tags,
		onlyFreeContent,
		includeGuestRating,
		onlyVXSelectContent,
		onlyClassicContent
	) {
		return getFilteredActorVideoDataProperty(actorId, sortOrder, tags, onlyFreeContent, includeGuestRating, 'total', onlyVXSelectContent, onlyClassicContent);
	},

	getActorVideoCount: function(actorId) {
		const actorVideoCount = _actorVideoCounts[actorId];
		return typeof actorVideoCount === 'number' ? actorVideoCount : null;
	},

	getActorVideoTagsByActorId: function(actorId) {
		return _actorVideoTags[actorId] || null;
	},

	getVideoRecommendationsByActorId(actorId, sameActor) {
		return _videoRecommendationsByActorId[actorId] && _videoRecommendationsByActorId[actorId][sameActor] || null;
	},

	getVideoRecommendationsByAlbumId(albumId, sameActor) {
		return _videoRecommendationsByAlbumId[albumId] && _videoRecommendationsByAlbumId[albumId][sameActor] || null;
	},

	/**
	 * @param {String} actorName
	 * @param {Number} limit
	 * @param {String|Number} fsk
	 */
	getActorPicturesByName: function(actorName, limit = -1, fsk = 'none') {
		let actorId = null;

		if (actorName) {
			actorName = actorName.toLowerCase();
		}

		if (typeof _actorMapNameToId[actorName] !== 'undefined' && _actorMapNameToId[actorName] !== null) {
			actorId = _actorMapNameToId[actorName];
		}

		return this.getActorPictures(actorId, limit, fsk);
	},

	getActorPromotedVideo: function(actorId) {
		let actorVideo = null;
		if (typeof _actorPromotedVideos[actorId] !== 'undefined' && _actorPromotedVideos[actorId] !== null) {
			actorVideo = _actorPromotedVideos[actorId];
		}

		return actorVideo;
	},

	getPreliminaryActorData: function(actorName) {
		let actorData = null;

		if (actorName) {
			actorName = actorName.toLowerCase();
		}

		if (typeof _preliminaryActorData[actorName] !== 'undefined' && _preliminaryActorData[actorName] !== null) {
			actorData = _preliminaryActorData[actorName];
		}

		return actorData;
	},

	/**
	 * @param {number} actorId
	 * @param {string} ratingType
	 * @returns {Object|null}
	 */
	getActorChatRatings: function(actorId, ratingType) {
		const chatRatings = _actorChatRatings[actorId] || {};
		return chatRatings[ratingType] || null;
	},

	/**
	 * @param {number} actorId
	 * @returns {Object|null}
	 */
	getActorHeatMaps: function(actorId) {
		return _actorHeatMaps[actorId] || null;
	},

	/**
	 * @param {Number} actorId
	 * @param {string} ratingType
	 * @return {boolean}
	 */
	shouldShowRating: function(actorId, ratingType) {
		// no data at all
		if (!Object.hasOwn(_actorChatRatings, actorId)) {
			return false;
		}

		// not enough data
		const chatRatings = _actorChatRatings[actorId] || {};
		return Object.hasOwn(chatRatings, ratingType)
			&& Object.hasOwn(chatRatings[ratingType], 'countRatings')
			&& chatRatings[ratingType].countRatings >= 10;
	},

	/**
	 * @param {Number} actorId
	 * @param {Number} first
	 * @return {Array}
	 */
	getModelRecommendations: function(actorId, first = 4) {
		if (!Object.hasOwn(_actorRecommendedActors, actorId)) {
			return null;
		}

		return _actorRecommendedActors[actorId].slice(0, first);
	},

	/**
	 * @param {int} actorId
	 * @param {boolean} state
	 *
	 * @return {boolean}
	 */
	setActorPromotedVideoState: function(actorId, state) {
		if (typeof _actorPromotedVideos[actorId] !== 'undefined' && _actorPromotedVideos[actorId] !== null) {
			_actorPromotedVideos[actorId].isFavoriteAlbum = state;
			return true;
		}
		return false;
	},

	/**
	 * @param {int} actorId
	 * @param {bool} state
	 * @param {string} fsk
	 *
	 * @return {bool}
	 */
	setActorSedCardsPinnedState: function(actorId, state, fsk = 'none') {
		const key = this.getCacheKey(actorId, fsk);

		if (typeof _actorPictures[key] !== 'undefined'
			&& _actorPictures[key] !== null
		) {
			_actorPictures[key].isFavoriteAlbum = state;
			return true;
		}
		return false;
	},

	storeActorNote: function(actorId, comment) {
		if (typeof _actorNotes[actorId] !== 'undefined' && _actorNotes[actorId] !== null) {
			_actorNotes[actorId].comment = comment;
		}

		doFetch(Routes.getRoute(Routes.Names.ACTOR_NOTE_STORE), {
			actorId: actorId,
			comment: comment,
		}, Constants.HttpMethods.POST, true).then((result) => {
			processResult(result);
		});
	},

	/**
	 * @param {Number} actorId
	 * @param {Array} heatMaps
	 * @param {Number|Null} hoursToBeOnline
	 * @param {string} actorName
	 * @param {String|null} memo
	 * @param {Array} errors
	 */
	storeActorHeatMaps: function(actorId, heatMaps, hoursToBeOnline, actorName, memo, errors) {
		_actorHeatMaps[actorId] = {heatMaps, hoursToBeOnline, actorName, memo, errors};
	},

	addActorDataChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorData, callback);
	},

	removeActorDataChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorData, callback);
	},

	addActorDataNoResultListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorDataNoResult, callback);
	},

	removeActorDataNoResultListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorDataNoResult, callback);
	},

	addActorPicturesChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorPictures, callback);
	},

	removeActorPicturesChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorPictures, callback);
	},

	addActorVideosChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorVideos, callback);
	},

	removeActorVideosChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorVideos, callback);
	},

	addActorVideoTagsChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorVideoTags, callback);
	},

	removeActorVideoTagsChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorVideoTags, callback);
	},

	addVideoRecommendationsByActorIdChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.VideoRecommendationsByActorId, callback);
	},

	removeVideoRecommendationsByActorIdChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.VideoRecommendationsByActorId, callback);
	},

	addVideoRecommendationsByAlbumIdChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.VideoRecommendationsByAlbumId, callback);
	},

	removeVideoRecommendationsByAlbumIdChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.VideoRecommendationsByAlbumId, callback);
	},

	addActorPromotedVideoChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorPromotedVideo, callback);
	},

	removeActorPromotedVideoChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorPromotedVideo, callback);
	},

	addActorTopVideosChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorTopVideos, callback);
	},

	removeActorTopVideosChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorTopVideos, callback);
	},

	addProfilePageChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ProfilePage, callback);
	},

	removeProfilePageChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ProfilePage, callback);
	},

	addActorNoteChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorNote, callback);
	},

	removeActorNoteChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorNote, callback);
	},

	/**
	 * @param {Function} callback
	 */
	addActorChatRatingsChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorChatRatings, callback);
	},

	/**
	 * @param {Function} callback
	 */
	removeActorChatRatingsChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorChatRatings, callback);
	},

	getProfilePage: function() {
		return _profilePage;
	},

	/**
	 * @param {Function} callback
	 */
	addInterviewsChangeListener: function(callback) {
		this.addListener(CHANGE_EVENTS.ActorInterviews, callback);
	},

	/**
	 * @param {Function} callback
	 */
	removeInterviewsChangeListener: function(callback) {
		this.addListener(CHANGE_EVENTS.ActorInterviews, callback);
	},

	/**
	 * @param {Function} callback
	 * @return {void}
	 */
	addModelRecommendationsChangeListener: function(callback) {
		this.addListener(CHANGE_EVENTS.ActorRecommendedActors, callback);
	},

	/**
	 * @param {Function} callback
	 * @return {void}
	 */
	removeModelRecommendationsChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorRecommendedActors, callback);
	},

	addActorOnlineStateChangeListener: function(callback) {
		this.addListener(CHANGE_EVENTS.OnlineState, callback);
	},

	removeActorOnlineStateChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.OnlineState, callback);
	},

	emitActorOnlineStateChange: function(actorId, isOnline) {
		this.emit(CHANGE_EVENTS.OnlineState, actorId, isOnline);
	},

	/**
	 * @param {Number} actorId
	 * @param {String} imageSize
	 * @param {Boolean} useFallback
	 * @param {String} durationFormat
	 * @return {null|*}
	 */
	getActorInterviews: function(
		actorId,
		imageSize      = Constants.Interviews.IMAGE_SIZE_720,
		useFallback    = true,
		durationFormat = Constants.Interviews.DURATION_SEC
	) {
		const fallbackKey = useFallback ? 1 : 0;
		const exists      = Object.hasOwn(_actorInterviews, actorId)
			&& Object.hasOwn(_actorInterviews[actorId], imageSize)
			&& Object.hasOwn(_actorInterviews[actorId][imageSize], fallbackKey)
			&& Object.hasOwn(_actorInterviews[actorId][imageSize][fallbackKey], durationFormat);

		return exists ? _actorInterviews[actorId][imageSize][fallbackKey][durationFormat] : [];
	},

	addActorSeoContentChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.ActorSeoContent, callback);
	},

	removeActorSeoContentChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.ActorSeoContent, callback);
	},

	getActorSeoContent: function(actorId, tagGroup) {
		if (_actorSeoContent[actorId] && _actorSeoContent[actorId][tagGroup]) {
			return _actorSeoContent[actorId][tagGroup];
		}
	},
});

/**
 * @param {string} sortOrder
 * @param {string[]} tags
 * @param {boolean} onlyFreeContent
 * @param {boolean} includeGuestRating
 * @param {boolean} onlyVXSelectContent
 * @param {boolean} onlyClassicContent
 * @return {object}
 */
function getActorVideoDataOptions(sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent) {
	const normalizedTags = Array.isArray(tags) ? tags.filter((tag, index) => tags.indexOf(tag) === index).sort() : [];

	return {
		sortOrder,
		onlyFreeContent,
		includeGuestRating,
		tags: normalizedTags,
		onlyVXSelectContent,
		onlyClassicContent,
	};
}

/**
 * @param {object} a
 * @param {object} b
 * @return {boolean}
 */
function areActorVideoDataOptionsEqual(a, b) {
	return a.sortOrder === b.sortOrder
		&& a.onlyFreeContent === b.onlyFreeContent
		&& a.includeGuestRating === b.includeGuestRating
		&& a.tags.length === b.tags.length
		&& a.tags.reduce((accumulator, tag, index) => accumulator && b.tags[index] === tag, true)
		&& a.onlyVXSelectContent === b.onlyVXSelectContent;
}

/**
 * @return {*|null}
 */
function getFilteredActorVideoData(actorId, sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent) {
	const actorVideoData = _actorFilteredVideoData[actorId];
	if (!actorVideoData) {
		return null;
	}
	const options = getActorVideoDataOptions(sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent);

	for (const entry of actorVideoData) {
		if (areActorVideoDataOptionsEqual(entry.options, options)) {
			return entry.data;
		}
	}

	return null;
}

/**
 * @return {*|null}
 */
function getFilteredActorVideoDataProperty(
	actorId,
	sortOrder,
	tags,
	onlyFreeContent,
	includeGuestRating,
	property,
	onlyVXSelectContent,
	onlyClassicContent
) {
	const videoData = getFilteredActorVideoData(actorId, sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent);
	return videoData && videoData[property] !== undefined ? videoData[property] : null;
}

function processResult(result) {
	let changeEvent = null;

	if (result.actorData) {
		const actorName                 = result.actorData.name.toLowerCase();
		_actorMapNameToId[actorName]    = result.actorData.id;
		_actorData[result.actorData.id] = result.actorData;
		_actorDataNoResult              = false;
		changeEvent                     = CHANGE_EVENTS.ActorData;
	} else if (result.data && result.data.actor) {
		const actorName              = result.data.actor.name.toLowerCase();
		_actorMapNameToId[actorName] = result.data.actor.id;
	} else {
		_actorDataNoResult = true;
		changeEvent        = CHANGE_EVENTS.ActorDataNoResult;
	}

	if (result.actorState && _actorData[result.actorState.id]) {
		let s;
		for (s in result.actorState) {
			_actorData[result.actorState.id][s] = result.actorState[s];
		}
		changeEvent = CHANGE_EVENTS.ActorData;
	}

	if (result.data) {
		if (typeof result.data.photos !== 'undefined') {
			const key           = ActorStore.getCacheKey(result.data.actor?.id, result.data.fsk || 'none');
			_actorPictures[key] = result.data;
			changeEvent         = CHANGE_EVENTS.ActorPictures;
		} else if (typeof result.data.promotedVideo !== 'undefined') {
			_actorPromotedVideos[result.data.actor.id] = result.data;
			changeEvent                                = CHANGE_EVENTS.ActorPromotedVideo;
		} else if (typeof result.data.topVideos !== 'undefined') {
			_actorTopVideos[result.data.actor.id] = result.data.topVideos;
			changeEvent                           = CHANGE_EVENTS.ActorTopVideos;
		}
	}

	if (typeof result.actorNote !== 'undefined') {
		_actorNotes[result.actorNote.actorId] = result.actorNote;
		changeEvent                           = CHANGE_EVENTS.ActorNote;
	}

	if (changeEvent) {
		ActorStore.emit(changeEvent);
	}
}

function loadActorData(actorIdOrName) {
	if (actorIdOrName && typeof actorIdOrName === 'string') {
		actorIdOrName = actorIdOrName.toLowerCase();
	}

	if (typeof _actorData[actorIdOrName] !== 'undefined') {
		_actorData[actorIdOrName] = null;
	}

	let payload     = null;
	const urlParams = getUrlParams(window.location.href);
	if (typeof urlParams.qmday !== 'undefined') {
		payload = {qmday: urlParams.qmday};
	}

	doFetch(Routes.getRoute(Routes.Names.ACTOR_DATA_BY_ID_OR_NAME, {actorIdOrName: actorIdOrName}), payload, Constants.HttpMethods.GET, true).then((result) => {
		processResult(result);
	});
}

function loadActorDataByName(actorName) {
	let actorIdOrName = actorName;

	if (actorIdOrName && typeof actorIdOrName === 'string') {
		actorIdOrName = actorIdOrName.toLowerCase();
	}

	if (typeof _actorMapNameToId[actorName] !== 'undefined' && _actorMapNameToId[actorName] !== null) {
		actorIdOrName = _actorMapNameToId[actorName];
	}

	loadActorData(actorIdOrName);
}

/**
 * @param {String|Number} actorIdOrName
 * @param {Number} limit
 * @param {String|Number} fsk
 */
function loadActorPictures(actorIdOrName, limit = -1, fsk = 'none') {
	if (actorIdOrName && typeof actorIdOrName === 'string') {
		actorIdOrName = actorIdOrName.toLowerCase();
	}

	if (typeof _actorPictures[actorIdOrName] !== 'undefined') {
		_actorPictures[actorIdOrName] = null;
	}

	const params = {
		actorIdOrName: actorIdOrName,
		payload:       JSON.stringify({
			limit: limit,
			fsk:   fsk,
		}),
	};

	doFetch(Routes.getRoute(Routes.Names.GALLERY_GET_SEDCARDS_BY_FSK, params), null, Constants.HttpMethods.GET, true).then((result) => {
		processResult(result);
	});
}

function loadFilteredActorVideos(
	actorId,
	count,
	offset,
	orders,
	includeGuestRating,
	tags                = [],
	onlyFreeContent     = false,
	fetchAllVideosCount = false,
	onlyVXSelectContent,
	onlyClassicContent
) {
	Flux.Vxql.getFilteredActorVideos(actorId, count, offset, orders, includeGuestRating, tags, onlyFreeContent, fetchAllVideosCount, onlyVXSelectContent, onlyClassicContent).then(({data}) => {
		if (data.model) {
			updateActorFilteredVideoData(actorId, count, offset, orders, includeGuestRating, tags, onlyFreeContent, fetchAllVideosCount, onlyVXSelectContent, onlyClassicContent, data.model);
		}
	});
}

function loadFilteredActorsVideos(
	actorIds,
	count,
	offset,
	orders,
	includeGuestRating,
	tags                = [],
	onlyFreeContent     = false,
	fetchAllVideosCount = false,
	onlyVXSelectContent,
	onlyClassicContent
) {
	Flux.Vxql.getFilteredActorVideos(actorIds, count, offset, orders, includeGuestRating, tags, onlyFreeContent, fetchAllVideosCount, onlyVXSelectContent, onlyClassicContent).then(({data}) => {
		if (data.models) {
			data.models.map((model) => {
				updateActorFilteredVideoData(model.id, count, offset, orders, includeGuestRating, tags, onlyFreeContent, fetchAllVideosCount, onlyVXSelectContent, onlyClassicContent, model);
			});
		}
	});
}

function updateActorFilteredVideoData(
	actorId,
	count,
	offset,
	orders,
	includeGuestRating,
	tags,
	onlyFreeContent,
	fetchAllVideosCount,
	onlyVXSelectContent,
	onlyClassicContent,
	newVideoData
) {

	let actorFilteredVideoData = _actorFilteredVideoData[actorId];

	if (!actorFilteredVideoData) {
		actorFilteredVideoData           = [];
		_actorFilteredVideoData[actorId] = actorFilteredVideoData;
	}

	const newVideoDataKeys = Object.keys(newVideoData).filter(key => key !== 'totalCount');

	for (const sortOrder of newVideoDataKeys) {
		if (sortOrder === 'id') {
			continue;
		}
		let videoData = getFilteredActorVideoData(actorId, sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent);

		if (!videoData) {
			videoData = {};
			actorFilteredVideoData.unshift({
				options: getActorVideoDataOptions(sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent),
				data:    videoData,
			});
		}

		const newVideoDataForSortOrder = newVideoData[sortOrder];

		videoData.total = newVideoDataForSortOrder.total;

		let videos = videoData.items;

		if (!videos) {
			videos          = {};
			videoData.items = videos;
		}
		for (const [index, video] of newVideoDataForSortOrder.items.entries()) {
			videos[offset + index] = video;
		}
	}

	if (fetchAllVideosCount) {
		_actorVideoCounts[actorId] = newVideoData.totalCount.total;
	}

	ActorStore.emit(CHANGE_EVENTS.ActorVideos, actorId);
}

function loadActorVideoTags(actorId) {
	Flux.Vxql.getActorVideoTags(actorId).then(({data}) => {
		if (data && data.model && data.model.videoTags) {
			_actorVideoTags[actorId] = data.model.videoTags;

			ActorStore.emit(CHANGE_EVENTS.ActorVideoTags, actorId);
		}
	});
}

function storeVideoRecommendations(recommendationsObject, id, sameActor, stateObject) {
	const data = recommendationsObject[sameActor ? 'fromSameActor' : 'fromDifferentActor'];

	if (data) {
		let recommendationsForId = stateObject[id];

		if (!recommendationsForId) {
			recommendationsForId = {};
			stateObject[id]      = recommendationsForId;
		}

		recommendationsForId[sameActor] = data;
	}
}

/**
 * @param {number} actorId
 * @param {number} countFromSameActor
 * @param {number} countFromDifferentActor
 */
function loadVideoRecommendationsByActorId(actorId, countFromSameActor, countFromDifferentActor) {
	if (_videoRecommendationsPromises.existsByActorId(actorId, countFromSameActor, countFromDifferentActor)) {
		return;
	}
	const promise = Flux.Vxql.getVideoRecommendationsByActorId(actorId, countFromSameActor, countFromDifferentActor);
	_videoRecommendationsPromises.setByActorId(actorId, countFromSameActor, countFromDifferentActor, promise);
	promise.then(({data}) => {
		const recommendations = data.model.recommendations;

		if (recommendations) {
			if (countFromSameActor > 0) {
				storeVideoRecommendations(recommendations, actorId, true, _videoRecommendationsByActorId);
			}
			if (countFromDifferentActor > 0) {
				storeVideoRecommendations(recommendations, actorId, false, _videoRecommendationsByActorId);
			}

			// Note: lacks sameActor param
			ActorStore.emit(CHANGE_EVENTS.VideoRecommendationsByActorId, actorId);
		}
	});
}

/**
 * @param {number} albumId
 * @param {number} countFromSameActor
 * @param {number} countFromDifferentActor
 */
function loadVideoRecommendationsByAlbumId(albumId, countFromSameActor, countFromDifferentActor) {
	if (_videoRecommendationsPromises.existsByAlbumId(albumId, countFromSameActor, countFromDifferentActor)) {
		return;
	}
	const promise = Flux.Vxql.getVideoRecommendationsByAlbumId(albumId, countFromSameActor, countFromDifferentActor);
	_videoRecommendationsPromises.setByAlbumId(albumId, countFromSameActor, countFromDifferentActor, promise);
	promise.then(({data}) => {
		const recommendations = data.video.recommendations;

		if (recommendations) {
			if (countFromSameActor > 0) {
				storeVideoRecommendations(recommendations, albumId, true, _videoRecommendationsByAlbumId);
			}
			if (countFromDifferentActor > 0) {
				storeVideoRecommendations(recommendations, albumId, false, _videoRecommendationsByAlbumId);
			}

			// Note: lacks sameActor param
			ActorStore.emit(CHANGE_EVENTS.VideoRecommendationsByAlbumId, albumId);
		}
	});
}

/**
 * @param {Object} chatRatings
 * @param {Number} actorId
 */
function cacheActorChatRatings(chatRatings, actorId) {
	if (typeof _actorChatRatings[actorId] === 'undefined') {
		_actorChatRatings[actorId] = {};
	}

	if (chatRatings && chatRatings.lastNinetyDays) {
		_actorChatRatings[actorId] = {..._actorChatRatings[actorId], ...chatRatings};
		ActorStore.emit(CHANGE_EVENTS.ActorChatRatings, actorId);
	}
}

function loadActorTopVideos(actorIdOrName) {
	if (actorIdOrName && typeof actorIdOrName === 'string') {
		actorIdOrName = actorIdOrName.toLowerCase();
	}

	if (typeof _actorTopVideos[actorIdOrName] !== 'undefined') {
		_actorTopVideos[actorIdOrName] = null;
	}

	doFetch(Routes.getRoute(Routes.Names.GALLERY_GET_TOP_VIDEOS, {actorIdOrName: actorIdOrName}), null, Constants.HttpMethods.GET, true).then((result) => {
		processResult(result);
	});
}

function loadActorPromotedVideo(actorId) {
	_actorPromotedVideos[actorId] = null;

	doFetch(Routes.getRoute(Routes.Names.GALLERY_GET_PROMOTED_VIDEO, {actorId: actorId}), null, Constants.HttpMethods.GET, true).then((result) => {
		processResult(result);
	});
}

function loadActorState(actorId) {
	doFetch(Routes.getRoute(Routes.Names.ACTOR_STATE_BY_ID, {actorId: actorId}), null, Constants.HttpMethods.GET, true).then((result) => {
		processResult(result);
	});
}

function loadActorNote(actorId) {
	if (_actorNotesLoading[actorId]) {
		return;
	}
	
	_actorNotes[actorId]        = null;
	_actorNotesLoading[actorId] = true;
	doFetch(Routes.getRoute(Routes.Names.ACTOR_NOTE_BY_ID, {actorId: actorId}), null, Constants.HttpMethods.GET, true).then((result) => {
		processResult(result);
		_actorNotesLoading[actorId] = false;
	});
}

function setPreliminaryData(actorData) {
	if (actorData) {
		const actorName = actorData.name.toLowerCase();

		_preliminaryActorData[actorName] = actorData;
	}
}

function setProfilePage(pageId) {
	if (pageId !== _profilePage) {
		_profilePage = pageId;
		ActorStore.emit(CHANGE_EVENTS.ProfilePage);
	}
}

/**
 * @param {Array} interviews
 * @param {Number} actorId
 * @param {String} imageSize
 * @param {Boolean} useFallback
 * @param {String} durationFormat
 */
function saveInterviewsInCache(interviews, actorId, imageSize, useFallback = true, durationFormat = Constants.Interviews.DURATION_SEC) {
	_actorInterviews = mergeDeep({}, _actorInterviews, {
		[actorId]: {
			[imageSize]: {
				[useFallback ? 1 : 0]: {
					[durationFormat]: interviews,
				},
			},
		},
	});
	interviews.length > 0 && ActorStore.emit(CHANGE_EVENTS.ActorInterviews, actorId);
}

/**
 * @param actorId
 * @param models
 */
function cacheActorRecommendedActors(actorId, models) {
	if (!Object.hasOwn(_actorRecommendedActors, actorId)) {
		_actorRecommendedActors[actorId] = [];
	}

	if (Array.isArray(models)) {
		_actorRecommendedActors[actorId] = models;
		ActorStore.emit(CHANGE_EVENTS.ActorRecommendedActors, actorId);
	}
}

function loadActorSeoContent(actorId, tagGroup) {
	if(_actorSeoContent[actorId] && _actorSeoContent[actorId][tagGroup]) {
		return;
	}

	doFetch(Routes.getRoute(Routes.Names.ACTOR_SEO_CONTENT, {
		actorId:  actorId,
		tagGroup: encodeURIComponent(tagGroup)
	}), null, Constants.HttpMethods.GET, true).then((result) => {
		if (!_actorSeoContent[actorId]) {
			_actorSeoContent[actorId] = {};
		}
		_actorSeoContent[actorId][tagGroup] = result;

		ActorStore.emit(CHANGE_EVENTS.ActorSeoContent, actorId);
	});
}

ActorStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActorActionTypes.LOAD_DATA:
			if (typeof action.actorName !== 'undefined') {
				loadActorDataByName(action.actorName);
			} else {
				loadActorData(action.actorId);
			}
			break;
		case ActorActionTypes.LOAD_PICTURES:
			loadActorPictures(action.actorId, action.limit, action.fsk);
			break;
		case ActorActionTypes.LOAD_VIDEOS:
			loadFilteredActorVideos(action.actorId, action.count, action.offset, action.orders, action.includeGuestRating, action.tags, action.onlyFreeContent, action.fetchAllVideosCount, action.onlyVXSelectContent, action.onlyClassicContent);
			break;
		case ActorActionTypes.LOAD_VIDEOS_ACTORS:
			loadFilteredActorsVideos(action.actorIds, action.count, action.offset, action.orders, action.includeGuestRating, action.tags, action.onlyFreeContent, action.fetchAllVideosCount, action.onlyVXSelectContent, action.onlyClassicContent);
			break;
		case ActorActionTypes.LOAD_VIDEO_TAGS:
			loadActorVideoTags(action.actorId);
			break;
		case ActorActionTypes.LOAD_VIDEO_RECOMMENDATIONS_BY_ACTOR_ID:
			loadVideoRecommendationsByActorId(action.actorId, action.countFromSameActor, action.countFromDifferentActor);
			break;
		case ActorActionTypes.LOAD_VIDEO_RECOMMENDATIONS_BY_ALBUM_ID:
			loadVideoRecommendationsByAlbumId(action.albumId, action.countFromSameActor, action.countFromDifferentActor);
			break;
		case ActorActionTypes.LOAD_TOP_VIDEOS:
			loadActorTopVideos(action.actorId);
			break;
		case ActorActionTypes.LOAD_PROMOTED_VIDEO:
			loadActorPromotedVideo(action.actorId);
			break;
		case ActorActionTypes.LOAD_STATE:
			loadActorState(action.actorId);
			break;
		case ActorActionTypes.LOAD_NOTE:
			loadActorNote(action.actorId);
			break;
		case ActorActionTypes.SET_PRELIMINARY_DATA:
			setPreliminaryData(action.actorData);
			break;
		case ActorActionTypes.SET_PROFILE_PAGE:
			setProfilePage(action.pageId);
			break;
		case ActorActionTypes.LOADED_CHAT_RATINGS:
			cacheActorChatRatings(action.chatRatings, action.actorId);
			break;
		case ActorActionTypes.LOADED_INTERVIEWS:
			saveInterviewsInCache(action.interviews, action.actorId, action.imageSize, action.useFallback, action.durationFormat);
			break;
		case ActorActionTypes.LOADED_RECOMMENDED_MODELS:
			cacheActorRecommendedActors(action.actorId, action.recommendations);
			break;
		case ActorActionTypes.LOAD_SEO_CONTENT:
			loadActorSeoContent(action.actorId, action.tagGroup);
			break;
		default:
	}
});

export default ActorStore;
