'use strict';

import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';
import Routes           from '../../utils/Routes';
import {doFetch}        from "../../utils/CommonUtils";

const ActionTypes = Constants.ActionTypes.GuestProfile;

const CHANGE_EVENT = 'guestProfile';
const CONFIG_EVENT = 'guestConfig';

let _guestProfile = {};
let _guestConfig  = {};

function setGuestProfile(guestProfile) {
	_guestProfile = guestProfile;
}

function getGuestProfile() {
	doFetch(Routes.getRoute(Routes.Names.GUEST_PROFILE_GET), {}, Constants.HttpMethods.GET, true).then((result) => {
		_guestProfile = {...result.data.profile};
		_guestConfig  = {...result.data.config};
		GuestProfileStore.emitGuestConfigChange();
		GuestProfileStore.emitGuestProfileChange();
	});
}

function updateProperties(properties) {
	assign(_guestProfile, properties);
	GuestProfileStore.emitGuestProfileChange();
}

function saveProperties() {
	doFetch(Routes.getRoute(Routes.Names.GUEST_PROFILE_UPDATE), {properties: _guestProfile}, Constants.HttpMethods.POST, true);
}

function updateProfilePreview(shouldDelete) {
	// REST call
	doFetch(Routes.getRoute(Routes.Names.GUEST_PROFILE_PREVIEW_UPDATE), {'delete': !!shouldDelete}, Constants.HttpMethods.POST, true);
}

Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.GET_GUEST_PROFILE:
			getGuestProfile();
			break;
		case ActionTypes.SET_GUEST_PROFILE:
			setGuestProfile(action.guestProfile);
			break;
		case ActionTypes.UPDATE_PROPERTIES:
			updateProperties(action.properties);
			break;
		case ActionTypes.SAVE_PROPERTIES:
			saveProperties();
			break;
		case ActionTypes.DELETE_PICTURE:
			updateProfilePreview(true);
			break;
		// no default
	}
});

const GuestProfileStore = assign({}, FluxEventEmitter.prototype, {

	emitGuestConfigChange: function() {
		this.emit(CONFIG_EVENT);
	},

	addGuestConfigChangeListener: function(callback) {
		this.on(CONFIG_EVENT, callback);
	},

	removeGuestConfigChangeListener: function(callback) {
		this.removeListener(CONFIG_EVENT, callback);
	},

	getGuestConfig: function() {
		return _guestConfig;
	},

	emitGuestProfileChange: function() {
		this.emit(CHANGE_EVENT);
	},

	addGuestProfileChangeListener: function(callback) {
		this.on(CHANGE_EVENT, callback);
	},

	removeGuestProfileChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENT, callback);
	},

	getGuestProfile: function() {
		return _guestProfile;
	},

	deleteProfilePreview: function() {
		updateProfilePreview(true);
	},

	updateProfilePreview: function() {
		updateProfilePreview();
	},

});

export default GuestProfileStore;
