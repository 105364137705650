import Dispatcher               from '../Dispatcher';
import FluxEventEmitter         from '../FluxEventEmitter';
import Constants                from '../Constants';
import assign                   from 'object-assign';
import Flux                     from "../Flux";


const VideoV2ActionTypes = Constants.ActionTypes.VideoV2;
const CHANGE_EVENTS      = {
	VideoData: 'videoDataChange',
	VideoDataLoad: 'videoDataLoad',
};

const _defaultFilter = {modelIds: null, actingGroups: null, ids: null, tags: null, releasedFrom: null, releasedUntil: null, types: null};

const _defaultFilterForCategory = {
	allVideos:        {
		actingGroups: [],
		types:        [{premium: true, bought: false, free: false, classic: false, shared: false}],
	},
	discountedVideos: {
		actingGroups: [],
		types:        [{discount: true, bought: false, free: false, premium: true, vip: false, shared: false}],
	},
	trendingVideos:   {
		actingGroups: [],
		releasedFrom: new Date(new Date().setDate(new Date().getDate() - 8)),
		types:        [{premium: true, trending: true, bought: false, free: false, shared: false}],
	},
	freeVideos:       {
		actingGroups: [Constants.Vxql.Videos.ActingGroups.FEMALES],
		types:        [{discount: false, bought: false, free: true, shared: false}],
	},
	topVideos:        {
		actingGroups: [],
		releasedFrom: new Date(new Date().setMonth(new Date().getMonth() - 9)),
		types:        [{premium: true, bought: false, free: false, shared: false}],
	},
	vip30Videos:      {
		types:        [{vip30: true}],
	},
	boughtVideos:      {
		types:        [{bought: true}],
	},
};

const _defaultOrderForCategory = {
	allVideos:        Constants.Vxql.Videos.SortOrders.NEWEST,
	discountedVideos: Constants.Vxql.Videos.SortOrders.NEWEST,
	trendingVideos:   Constants.Vxql.Videos.SortOrders.TOP_TRENDING,
	freeVideos:       Constants.Vxql.Videos.SortOrders.NEWEST,
	topVideos:        Constants.Vxql.Videos.SortOrders.BEST_SELLING,
	vip30Videos:      Constants.Vxql.Videos.SortOrders.TOP_RATED,
	boughtVideos:     Constants.Vxql.Videos.SortOrders.NEWEST,
};

const _videoData = {};
const _videoDataLoading = {};


const VideoV2Store = assign({}, FluxEventEmitter.prototype, {

	/**
	 * @param {string} category
	 * @returns {Object}
	 */
	getDefaultFilterForCategory: function(category) {
		if (!_defaultFilterForCategory[category]) {
			throw new Error('Unmapped category');
		}
		return {..._defaultFilter, ..._defaultFilterForCategory[category]};
	},

	/**
	 * @param {string} category
	 * @returns {Object}
	 */
	getDefaultOrderForCategory: function(category) {
		if (!_defaultOrderForCategory[category]) {
			throw new Error('Unmapped category');
		}
		return _defaultOrderForCategory[category];
	},

	/**
	 * @param {Object} filter
	 * @param {string} order
	 * @returns {Object[]|null}
	 */
	getVideos: function(filter, order) {
		let videos = null;
		const key  = getKey(filter, order);
		if (_videoData[key]) {
			videos = _videoData[key]['items'] || null;
		}
		return videos;
	},

	/**
	 * @param {Object} filter
	 * @param {string} order
	 * @returns {*|null}
	 */
	getVideoTotalCount: function(filter, order) {
		let total = null;
		const key = getKey(filter, order);
		if (_videoData[key]) {
			total = _videoData[key]['total'] || null;
		}
		return total;
	},

	/**
	 *
	 * @param {Object} filter
	 * @param {string} order
	 * @returns {boolean}
	 */
	isVideoDataLoading: function(filter, order) {
		const key = getKey(filter, order);

		return _videoDataLoading[key] || false;
	},


	addVideoDataChangeListener: function(callback) {
		this.on(CHANGE_EVENTS.VideoData, callback);
	},

	removeVideoDataChangeListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.VideoData, callback);
	},

	addVideoDataLoadListener: function(callback) {
		this.on(CHANGE_EVENTS.VideoDataLoad, callback);
	},

	removeVideoDataLoadListener: function(callback) {
		this.removeListener(CHANGE_EVENTS.VideoDataLoad, callback);
	},


});

function getKey(filter, order) {
	return JSON.stringify({...getShapedFilter(filter), order}); //@TODO: hash the stringified object?
}

function getShapedFilter(filter) {
	// add missing filter values and sets defined order of key(so objects can be compared using stringify)
	return {..._defaultFilter, ...filter};
}

function loadVideos(
	filter,
	order,
	count,
	offset = 0,
	id,
) {
	filter = getShapedFilter(filter);
	const key = getKey(filter, order);

	_videoDataLoading[key] = true;
	VideoV2Store.emit(CHANGE_EVENTS.VideoDataLoad);
	Flux.Vxql.getVideosFromVXQL(filter, order, count, offset).then(({data}) => {
		const newVideoData = data.videos; //@TOOD: handle error

		if (newVideoData) {
			let videoData = _videoData[key];

			if (!videoData) {
				videoData = {};
			}


			videoData.total = newVideoData.total;

			let videos = videoData.items;

			if (!videos) {
				videos          = {};
				videoData.items = videos;
			}

			for (const [index, video] of newVideoData.items.entries()) {
				videos[offset + index] = video;
			}

			_videoData[key] = videoData;

			_videoDataLoading[key] = false;


			VideoV2Store.emit(CHANGE_EVENTS.VideoDataLoad);
			VideoV2Store.emit(CHANGE_EVENTS.VideoData, id);
		}
	});
}

VideoV2Store.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case VideoV2ActionTypes.LOAD_VIDEOS:
			loadVideos(action.filter, action.order, action.count, action.offset, action.id);
			break;

		default:
		// ??
	}
});

export default VideoV2Store;
