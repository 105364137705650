'use strict';

import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import {doFetch}        from "../../utils/CommonUtils";
import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';

const ActionTypes = Constants.ActionTypes.Breadcrumb;
const ChangeEvent = 'BreadCrumb';

const breadcrumbs = {};

function loadBreadcrumb(breadcrumbType, payload, breadcrumbKey) {

	doFetch(Routes.getRoute(Routes.Names.BREADCRUMB, {breadcrumbType: breadcrumbType}), {payload: payload}, Constants.HttpMethods.POST, true)
		.then((data) => {
			breadcrumbs[breadcrumbKey] = data.data.breadcrumb;
			BreadcrumbStore.emit(ChangeEvent, breadcrumbType, breadcrumbKey);
		});
}

const BreadcrumbStore = assign({}, FluxEventEmitter.prototype, {
	addBreadcrumbChangeListener: function(callback) {
		this.on(ChangeEvent, callback);
	},

	removeBreadcrumbChangeListener: function(callback) {
		this.removeListener(ChangeEvent, callback);
	},

	getBreadcrumb: function(key) {
		return breadcrumbs[key];
	},
});

BreadcrumbStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.LOAD_BREADCRUMB:
			loadBreadcrumb(action.breadcrumbType, action.data, action.breadcrumbKey);
			break;
		default:
	}
});

export default BreadcrumbStore;
